/* 
.hero {
	height: 100%;
}
.features .feature-icons .content .icon-box h4 {
  font-size: 22px;
  font-weight: 700;
  margin: 0 0 10px 0;
  color: #012970;
}
.faq .card .card-header .faq-title {
  width: 100%;
  text-align: left;
  padding: 0px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 1px;
  color: #3B566E;
  text-decoration: none !important;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  padding-top: 20px;
  padding-bottom: 20px;
}
.display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
}

.modal-open[style] {
padding-right: 0px !important;
}

.modal {
 overflow-y: auto;
}

.modal-open {
 overflow: auto;
}

.me-4 {
  margin-right: 0rem !important;
}

.modal-open[style] {
padding-right: 0px !important;
}

.packageInclude {
	margin-top: -5%;
}

.docRequired {
	margin-top: 0%;
}

@media ( max-width : 991px) {
	.hero {
		height: auto;
		padding: 0px 0 0px 0;
	}
}
.Topbox {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	border: 0px solid red;
}

.topcontent {
	width: 50%;
	border: 0px solid red;
}

.topimg {
	width: 50%;
	border: 0px solid red;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,0.125);
  border-radius: 17px;
}

@media screen and (max-width:991px) {
	.Topbox {
		flex-wrap: wrap;
	}
	.topcontent {
		width: 100%;
		border: 0px solid red;
	}
	.topcontent h1, h2 {
		text-align: center;
	}
	.topimg {
		width: 100%;
		border: 0px solid red;
		display: flex;
		align-items: center;
		justify-content: space-around;
	}
}

.advantageTop {
	margin-top: 0%;
}

@media ( max-width : 768px) {
	.packageInclude {
		margin-top: 0%;
	}
	.docRequired {
		margin-top: 0%;
	}
	.advantageTop {
		margin-top: 0%;
	}
	.stepTop {
		margin-top: 0%;
	}
	
	.opcTop{
	  margin-top:10%;
	}
}

.modal {
	background-color: #0000008c !important;
	opacity: 1 !important;
} */

.hero {
  height: 100%;
}
.features .feature-icons .content .icon-box h4 {
  font-size: 22px;
  font-weight: 700;
  margin: 0 0 10px 0;
  color: #012970;
}
.faq .card .card-header .faq-title {
  width: 100%;
  text-align: left;
  padding: 0px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 1px;
  color: #3b566e;
  text-decoration: none !important;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  padding-top: 20px;
  padding-bottom: 20px;
}
.display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
}

.modal-open[style] {
  padding-right: 0px !important;
}

.modal {
  overflow-y: auto;
}

.modal-open {
  overflow: auto;
}

.me-4 {
  margin-right: 0rem !important;
}

.modal-open[style] {
  padding-right: 0px !important;
}

.packageInclude {
  margin-top: -5%;
}

.docRequired {
  margin-top: 0%;
}

@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 0px 0 0px 0;
  }
}

/* @media screen and (max-width:1350px) {
	  section {
		  padding: 0px 0;
	  }
  } */
.Topbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0px solid red;
}

.topcontent {
  width: 50%;
  border: 0px solid red;
}

.topimg {
  width: 50%;
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 17px;
}

@media screen and (max-width: 991px) {
  .Topbox {
    flex-wrap: wrap;
  }
  .topcontent {
    width: 100%;
    border: 0px solid red;
  }
  .topcontent h1,
  h2 {
    text-align: center;
  }
  .topimg {
    width: 100%;
    border: 0px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.advantageTop {
  margin-top: 0%;
}

@media (max-width: 768px) {
  .packageInclude {
    margin-top: 0%;
  }
  .docRequired {
    margin-top: 0%;
  }
  .advantageTop {
    margin-top: 0%;
  }
  .stepTop {
    margin-top: 0%;
  }

  .opcTop {
    margin-top: 10%;
  }
  .whyRecon {
    margin-top: 0% !important;
  }
}
@media only screen and (max-width: 1024px) {
  #features {
    margin-top: 0% !important;
    padding-top: 0% !important;
  }
}

@media (max-width: 480px) {
  .whyRecon {
    margin-top: 0% !important;
  }
  .mobmar{
    margin-top: -15%;
  }
}

.modal {
  background-color: #0000008c !important;
  opacity: 1 !important;
}

