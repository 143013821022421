.iconColor {
  color: white;
}

.iconColor:hover {
  color: #406aff;
  border: transparent;
}

.isoImageCss {
  width: 30%;
  height: 30%;
}

.sslSecureImage {
  width: 50%;
  height: 30%;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: red !important;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

.navHead {
  visibility: visible;
  display: block ruby;
  animation-delay: 0.1s;
  top: -100px;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  box-shadow: 0px 3px 17px #00000047;
}
.navmargin {
  margin-right: -27rem;
}
/* For mobile navbar view */
/* .navbar .mobile-offcanvas {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: black;
}

.nav-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: var(--header-height);
  margin: 1rem;
}
.imageWidth {
  width: 100px;
  height: 50px;
}

.btn-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
} */

/* For Mobile NavBar View End  */

/* {
  width: 100%;
  overflow: hidden;
} */

.contact_fixed {
  display: block;
  width: 295px;
  height: auto;
  padding: 10px 10px 10px 10px;
  background-color: #65ac4e;
  color: #fff;
  -moz-border-radius: 7px 0px 0px 7px;
  -webkit-border-radius: 7px 0px 0px 7px;
  border-radius: 7px 0px 0px 7px;
  position: fixed;
  right: -150px;
  top: 490px;
  z-index: 9999;
  font-size: 15px;
  line-height: 1.7;
  box-shadow: 4px 3px 6px #000000;
}
.navbar-toggler {
  margin-left: 5%;
}
.imgCss {
  width: 210px;
  margin-right: 3rem !important;
}
.imageWidth {
  width: 186px;
}
.textTransform {
  text-transform: none;
}
.imageWidthNav {
  width: 210px;
}
.imageNavWidth {
  color: #0e306d;
}

.navMR {
  margin-left: 8%;
}
.colornav {
  color: #0e306d;
}
:root {
  --primary: #06bbcc;
  --light: #f0fbfc;
  --dark: #0f316d;
}

.fw-medium {
  font-weight: 600 !important;
}

.fw-semi-bold {
  font-weight: 700 !important;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

.btn {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  transition: 0.5s;
}

.btn.btn-primary,
.btn.btn-secondary {
  color: #ffffff;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 0px;
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

.navbar-light .navbar-nav .nav-link {
  margin-right: 30px;
  padding: 25px 0;
  color: #ffffff;
  font-size: 15px;
  text-transform: uppercase;
  outline: none;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--primary);
}

@media (max-width: 991.98px) {
  .navbar-light .navbar-nav .nav-link {
    margin-right: 0;
    padding: 10px 0;
  }

  .navbar-light .navbar-nav {
    border-top: 1px solid #eeeeee;
  }
}

.navbar-light .navbar-brand,
.navbar-light a.btn {
  height: 75px;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--dark);
  font-weight: 500;
}

.navbar-light.sticky-top {
  top: -100px;
  transition: 0.5s;
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: block;
    margin-top: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
  }

  .navbar .dropdown-menu.fade-down {
    top: 100%;
    transform: rotateX(-75deg);
    transform-origin: 0% 0%;
  }

  .navbar .nav-item:hover .dropdown-menu {
    top: 100%;
    transform: rotateX(0deg);
    visibility: visible;
    transition: 0.5s;
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .header-carousel .owl-carousel-item {
    position: relative;
    min-height: 500px;
  }

  .header-carousel .owl-carousel-item img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .fontcol{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.header-carousel .owl-nav {
  position: absolute;
  top: 50%;
  right: 8%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
}

.header-carousel .owl-nav .owl-prev,
.header-carousel .owl-nav .owl-next {
  margin: 7px 0;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: transparent;
  border: 1px solid #ffffff;
  font-size: 22px;
  transition: 0.5s;
}

.header-carousel .owl-nav .owl-prev:hover,
.header-carousel .owl-nav .owl-next:hover {
  background: var(--primary);
  border-color: var(--primary);
}

/* .page-header {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../../static/image/img/TDS_slider.jpg) center center no-repeat;
  background-size: cover;
} */

.page-header-inner {
  background: rgba(15, 23, 43, 0.7);
}

.breadcrumb-item + .breadcrumb-item::before {
  color: var(--light);
}

.section-title {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
}

.section-title::before {
  position: absolute;
  content: "";
  width: calc(100% + 80px);
  height: 2px;
  top: 4px;
  left: -40px;
  /* background: var(--primary); */
  z-index: -1;
}

.section-title::after {
  position: absolute;
  content: "";
  width: calc(100% + 120px);
  height: 2px;
  bottom: 5px;
  left: -60px;
  /* background: var(--primary); */
  z-index: -1;
}

.section-title.text-start::before {
  width: calc(100% + 40px);
  left: 0;
}

.section-title.text-start::after {
  width: calc(100% + 60px);
  left: 0;
}

.service-item {
  background: var(--light);
  transition: 0.5s;
}

.service-item:hover {
  margin-top: -10px;
  background-color: white;
}

.service-item * {
  transition: 0.5s;
}

.service-item:hover * {
  color: #0e306d !important;
}

.category img,
.course-item img {
  transition: 0.5s;
}

.category a:hover img,
.course-item:hover img {
  transform: scale(1.1);
}

.team-item img {
  transition: 0.5s;
}

.team-item:hover img {
  transform: scale(1.1);
}

.testimonial-carousel::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1;
}

.testimonial-carousel::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  height: 100%;
  width: 0;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1;
}

@media (min-width: 768px) {
  .testimonial-carousel::before,
  .testimonial-carousel::after {
    width: 200px;
  }
 
}

@media (min-width: 992px) {
  .testimonial-carousel::before,
  .testimonial-carousel::after {
    width: 300px;
  }
  #dontshow {
    margin-bottom: 20%;
  }
}
.testimonial-carousel .owl-item .testimonial-text,
.testimonial-carousel .owl-item.center .testimonial-text * {
  transition: 0.5s;
}

.testimonial-carousel .owl-item.center .testimonial-text {
  background: var(--primary) !important;
}

.testimonial-carousel .owl-item.center .testimonial-text * {
  color: #ffffff !important;
}

.testimonial-carousel .owl-dots {
  margin-top: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 15px;
  height: 15px;
  border: 1px solid #cccccc;
  transition: 0.5s;
}

.testimonial-carousel .owl-dot.active {
  background: var(--primary);
  border-color: var(--primary);
}

.footer .btn.btn-social {
  margin-right: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  font-weight: normal;
  border: 1px solid #ffffff;
  border-radius: 35px;
  transition: 0.3s;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: #ffffff;
  font-size: 15px;
  font-weight: normal;
  text-transform: capitalize;
  transition: 0.3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 25px 0;
  font-size: 15px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright a {
  color: var(--light);
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.dropdown-item {
  color: #333;
  position: relative;
  padding: 10px 20px;
  text-decoration: none;
  display: inline-block;
}

.dropdown-item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 17px;
  width: 0%;
  height: 2px;
  background-color: transparent;
  transition: width 0.4s ease-in-out, background-color 0.1s ease-in-out;
}

/* Hover effect */
.dropdown-item:hover::after {
  width: calc(100% - 17px);

  background-color: #16c1e9;
}

/* Base styling for the nav link */
.nav-link {
  color: #333; /* Default text color */
  text-decoration: none; /* Remove underline */
  font-weight: normal; /* Default font weight */
  transition: all 0.3s ease; /* Smooth transition for all properties */
}

/* Hover effect for the nav link */
.nav-link:hover {
  color: #16c1e9; /* Change the text color to your desired hover color */
  font-weight: bold; /* Make the font bold on hover */
  /* Underline the text on hover */
  transform: scale(1.05); /* Slightly scale up the text for a subtle effect */
}

@media (max-width: 1180px) {
  .navbar-brand .ipadMargin {
    margin-left: 0px;
  }
  .nav-item {
    text-align: center;
  }
  /* .hide-on-mobile {
    display: none;
  } */
  /* .navbar {
    display: none;
  }
  .navHead {
    margin-bottom: 100px;
  } */
}

@media (max-width: 991px) {
  .navbar-brand {
    margin-right: 100px !important;
  }
}

@media (max-width: 483px) {
  .navbar-toggler {
    margin-top: 2%;
  }
  .imgCss {
    margin-right: 0% !important;
    margin-top: 4%;
  }
}

.collapse {
  display: none;
}

@media (max-width: 992px) {
  .collapse {
    display: block;
  }
  .mobile-offcanvas {
    height: auto;
    width: 80%;
    /* height: 100%; */
    overflow-y: scroll;
    overflow-x: hidden;
    transition: 2s ease-in-out;
    /* margin-top: -350px; */
    padding-top: 11% !important;
  }
  .mobile-offcanvas.show {
    visibility: visible;
    transform: translateX(0);
  }
  .nav-link {
    text-align: start;
    padding-left: 10px !important ;
    position: relative;
  }
  .collapse {
    /* transform: translateY(300px); */
    transition: 0.5s;
  }
  /* .navbar {
    padding-top: 10% !important;
  } */
  /* .offcanvas-header {
    margin-top: 0% !important;
  } */
  .nav-cont {
    position: fixed;
    height: 100%;
  }
  .toAlign {
    display: flex;
    width: 100%;

    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
  .offcanvas-header {
    margin-bottom: 6%;
  }
}
@media (max-width: 480px) {
  .toAlign {
    width: 100% !important;
    display: flex;
    flex-direction: row-reverse;
  }
  .toAlign button {
    margin-left: -10% !important;
  }
}

@media (max-width: 350px) {
  .toAlign {
    width: 100% !important;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    align-items: center;
  }
  .toAlign button {
    margin-left: -20% !important;
  }
}

@media screen and (max-width: 768px) {
  .isoImageCss {
    width: 18%;
    height: 30%;
  }

  .sslSecureImage {
    width: 35%;
    height: 30%;
  }
}

@media (max-width: 744px) and (max-height: 883px) {
}

@media (max-width: 480px) {
  .navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-top: 0%;
    float: right;
  }
  .imgstyle {
    width: 100%;
  }
  .custom-style {
    padding: 20px !important;
  }
  .tdsFonts {
    font-size: 20px !important;
    white-space: nowrap;
  }
  .btn-get-started {
    padding: 4px 10px !important;
   
  }
  .getCss {
    font-size: 10px;
  }
  .slick-dots {
    bottom: 6px !important;
  }
  .importantCss {
    font-size: 15px;
  }

  .title::after {
    right: -61% !important;
    top: 0px !important;
  }
  .mob-css {
    margin-top: 10px;
  }
  .display-5 {
    display: block !important;
  }
  .row {
    margin-left: 0 !important;
    margin: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .row h3 {
    padding-top: 10px;
    padding-bottom: 0px;
  }
  .project .nav .nav-link.active {
    background: #0f306c;
    border-radius: 11px !important;
    height: 50px !important;
  }
  .project .nav .nav-link.active h3 {
    color: #ffffff !important;
    font-size: 18px !important;
    margin: 1px 0px 0px 24px !important;
  }
  .project .nav .nav-link {
    background: #0f306c;
    border-radius: 11px !important;
    height: 50px !important;
  }
  .project .nav .nav-link h3 {
    color: #0f306c !important;
    font-size: 18px !important;
    margin: 1px 0px 0px 24px !important;
  }
  .fact-item {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  .btn {
    padding: 11px 20px !important;
    font-weight: 400;
    font-size: 13px;
    width: 174px;
    height: 30px;

    white-space: nowrap;

    display: flex !important;
    align-items: center !important;
    border-radius: 10px !important;
  }
  p {
    margin: 0 !important;
    padding: 0 !important;
  }
  .container h3 {
    margin: 0 !important;
  }
  .row h3 {
    margin: 0 !important;
    padding: 0 !important;
  }
  .form-control {
    border-radius: 5px;
  }
  .btn1 {
    padding: 12px 55px !important;
    border-radius: 6px !important;
  }
  .mobbtn {
    margin-left: 18%;
  }
  .header {
    text-align: center;
    margin-bottom: 0px;
  }
  .section-title {
    margin-bottom: 10px !important;
  }
  .blog-card {
    margin-bottom: 35px !important;
  }
  .dropdown-menu.show{
    width:100%
  }
  .container {
    margin-top: 0%;
    padding: 0% !important;
  }
  .icons{
    margin-left:-13px !important
  }
  .util{
    margin-top:20px !important;
    margin-bottom:-20px
  }
  .cont {
    margin-top: -10% !important;
}
.hero .btn-get-started {
  margin-top: 30px;
  line-height: 0;
  padding: 7px 21px !important;
  border-radius: 6px;
  transition: 0.5s;
  color: #fff;
  background: #4154f1;
  box-shadow: 0px 5px 30px rgba(65, 84, 241, 0.4);
  /* width: 43% !important; */
}


.hero .btn-get-started span {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 12px !important;
  letter-spacing: 1px;
}

.mobBTN{
  margin-top: 30px;
  line-height: 0;
  padding: 7px 8px !important;
  border-radius: 6px;
  transition: 0.5s;
  color: #fff;
  background: #4154f1;
  box-shadow: 0px 5px 30px rgba(65, 84, 241, 0.4);
   width: 100% !important; 
}
.mobBTN span{
  font-weight: 600;
  font-size: 10px !important;
  letter-spacing: 0px;
  width: 100% !important;
  white-space: nowrap;
}
}

.footer .btn.btn-link{
  text-decoration: none !important;
}

.btn:focus-visible {
  color: none !important;
  background-color: none !important;
  border-color: none !important;
  outline: 0 !important;
  box-shadow: none !important;
}
.btn-link:focus-visible {
  color: none !important;
}
.btn-link:focus-visible {
  color: none !important;
}

