/* .page-header {
   background-size: auto, auto;
    background-size: cover;
  }

  .topimg img {
    width: 100%;
    margin-top: -1%;
    border-radius: 17px;
  }

  .display-5 {
    font-size: calc(0.3rem + 2.1vw);
    font-weight: 600;
    line-height: 1.2;
    display: block ruby;
  }
  .gstImage{
   width: 70%; margin-left: 26%;margin-top: 5%;
  }
.gstFonts{
  font-size:3.5em;
}

.contactUsCss{
  background-color:#5cb85c;
}
  .ismartImageCss{
    width: -moz-available;border-radius:3%; 
    background-size: auto, auto;
  background-size: cover
  }

  .ismartBG{
    background-color:#5cb85c;
  }
  
  .gstFont{
    color:#132f6b;font-size: calc(0.3rem + 2.1vw);
    font-weight: 600;
    line-height: 1.2;
  }
  
  .gstInvoiceImageCss{
    width:100%;margin-top: 38%;
  }
  @media screen and (max-width: 768px) {
   .gstImage{
   width: 70%; margin-left: 9%;margin-top: 5%;
  }
  
  .gstFont{
    color:#132f6b;font-size: calc(1rem + 2.1vw);
    font-weight: 600;
    line-height: 1.2;
  }
  } */

.page-header {
  background-size: auto, auto;
  background-size: cover;
}

.topimg img {
  width: 100%;
  margin-top: -1%;
  border-radius: 17px;
}

.display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
}
.gstImage {
  width: 70%;
  margin-left: 26%;
  margin-top: 5%;
}
.gstFonts {
  font-size: 3.5em;
}

.contactUsCss {
  background-color: #5cb85c;
}
.ismartImageCss {
  width: -moz-available;
  border-radius: 3%;
  background-size: auto, auto;
  background-size: cover;
}

.ismartBG {
  background-color: #5cb85c;
}

.gstFont {
  color: #132f6b;
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
}

.gstInvoiceImageCss {
  width: 100%;
  margin-top: 38%;
}
@media screen and (max-width: 768px) {
  .gstImage {
    width: 70%;
    margin-left: 9%;
    margin-top: 5%;
  }

  .gstFont {
    color: #132f6b;
    font-size: calc(1rem + 2.1vw);
    font-weight: 600;
    line-height: 1.2;
  }
  .ourServiceColor {
    font-size: 30px;
  }
  .stepsColor {
    font-size: 30px;
  }
  .content {
    margin-top: 0%;
    padding-top: 0%;
  }
  #hero {
    margin-top: 0%;
  }
  .Topbox {
    margin-top: -18% !important;
  }
  .stepsColor {
    padding-top: 8%;
  }
}
@media screen and (max-width: 991px) {
  #hero {
    margin-top: -5%;
  }
  .ourServiceColor {
    font-size: 40px;
  }
  .stepsColor {
    font-size: 40px;
  }
  /* .gstColorInvoice {
      font-size: 50px;
    } */
  .gstImage {
    margin-top: -10%;
    padding-top: 0%;
  }
}
@media (max-width: 480px) {
  .ourServiceColor {
    font-size: 30px;
  }
  .stepsColor {
    font-size: 30px;
  }
}
@media only screen and (max-width: 1024px) {
  #maincontainer {
    padding-bottom: 0% !important;
  }
}

h3{
  margin:0 !important;
}

/* .alignColumn{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

} */
 