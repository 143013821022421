.mui-card {
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  height: 300px;
  margin: 1rem;
  position: relative;
  width: 300px;
}

.xs-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.xs-shadow:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.xm-shadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.xm-shadow:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.md-shadow {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.md-shadow:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.xl-shadow {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.xl-shadow:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.xxl-shadow {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.xxl-shadow:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
::selection {
  color: white;
  background: red;
}

/* Adding new Css */
.mn_menus {
  background-color: #0f316d;
  height: 350px;
}
.mn_menus h1 {
  font-size: 40px;
  color: white;
  /* margin-left: 8%; */
  border-bottom: 1px solid #fff;
  border-bottom-width: 0px;
  margin-top: 30px;
}
.mn_menus h1 strong {
  color: #ffa500;
}
.mn_menus h2 {
  font-size: 30px;
  color: white;
  /* margin-top: 30px;
  margin-left: 10%; */
}
.mn_menus h3 {
  font-size: 20px;
  color: white;
  margin-top: 10px;
  margin-left: 2%;
  background-color: red;
  width: 25%;
  height: fit-content;
}
.mn_menus h4 {
  font-size: 20px;
  color: white;
  margin-top: 10px;
  margin-left: 4%;
  background-color: red;
  width: 26%;
  padding: 2px;
  height: fit-content;
}

.mobile {
  /* background-color: #210051;
  height: 350px; */
}
.mobile h1 {
  font-size: 40px;
  color: white;
  margin-left: 8%;
  border-bottom: 1px solid #fff;
  border-bottom-width: 0px;
  margin-top: 30px;
}

.mobile h2 {
  font-size: 30px;
  color: white;
  margin-top: 30px;
  margin-left: 10%;
}
.mobile h3 {
  font-size: 20px;
  color: white;
  margin-top: 10px;
  margin-left: 4%;
  background-color: red;
  width: 80%;
}
.mobile h4 {
  color: white;
  font-size: 25px;
  background-color: red;
  width: 80%;
  margin-left: 2%;
}
.center {
  font-size: 30px;
  color: #e9e9e9;
}
.center i {
  font-size: 60px;
  color: #e9e9e9;
}
.theory p {
  padding-left: 40px;
}

.gde {
  font-size: 30px;
  font-weight: normal;
  border-bottom: 2px solid #ddd;
  border-bottom-width: 2px;
  width: 380px;
  border-bottom-style: solid;
}
.fadeInUp {
  visibility: visible;
  animation-delay: 0.1s;
  animation-name: fadeInUp;
}
.carDev {
  color: #0e306d;
}
.mn_menu2 h3 {
  font-size: 20px;
  color: white;
  margin-top: 5px;
  margin-left: 2%;
  background-color: red;
  width: 40%;
}
.mn_menu2 h5 {
  color: white;
  font-size: 20px;
  background-color: red;
  width: 40%;
  margin-left: 2%;
}
.mobile h5 {
  color: white;
  font-size: 20px;
  background-color: red;
  width: 70%;
  margin-left: 2%;
  height: fit-content;
}

.panel-title {
  text-align: center;
  color: #0f316d;
  /* background-color: whitesmoke; */
}

.carOpp {
  color: #ffa500;
}

/* Ending new Css */

@media screen and (max-width: 939px) {
  .mn_menu {
    display: none;
  }
}

@media only screen and (min-width: 940px) {
  .mobile {
    display: none;
  }
}

@media only screen and (max-width:480px){
  .mn_menus h1{
    font-size: 28px;
  }
  .mn_menus h2 {
    font-size: 26px;
  }
  .theory p{
    padding-left: 51px !important;
  }
  .mn_menu2 h2{
    font-size: 28px;
  }
  .accordion-title{
    font-size: 16px;
  }
}

/* Accordion Table */

.panel {
  background-color: white;
  border-radius: 10px; /* Rounded corners */
  border: 1px solid #ddd; /* Light border for the panel */
  margin-bottom: 15px; /* Space between accordion items */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Light shadow effect */
}

/* Style the panel-heading */
.panel-heading {
  background-color: #f7f7f7; /* Light grey background for the heading */
  border-radius: 10px 10px 0 0; /* Rounded top corners */
  padding: 15px;
  cursor: pointer;
}

/* Style the accordion toggle (text and arrow) */
.accordion-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
  color: #333; /* Dark text */
  font-size: 20px;
}

.accordion-title {
  font-weight: bold;
  color: #0f316d;
}

/* Down arrow style */
.accordion-arrow {
  font-size: 20px;
  transition: transform 0.3s ease;
}

/* When the accordion is open, rotate the arrow */
.accordion-arrow.open {
  transform: rotate(-180deg); /* Arrow rotates when expanded */
}

/* Panel body padding */
.panel-body {
  padding: 15px;
}

/* List style */
ul {
  list-style-type: none;
  padding-left: 0;
}

li {
  margin-bottom: 8px;
}