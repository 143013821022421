/* .hero {
  height: 100%;
}
.topcontent h1 {
  color: #0f316d;
}
.order-first img {
  border-radius: 17px;
}

.faq .card .card-header .faq-title {
  width: 100%;
  text-align: left;
  padding: 0px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 1px;
  color: #3b566e;
  text-decoration: none !important;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  padding-top: 20px;
  padding-bottom: 20px;
}

.services .card {
  border: 1px solid rgba(14, 29, 52, 0.15);
  background: #fff;
  position: relative;
  border-radius: 17px;
  height: 100%;
}
.display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
}
.me-4 {
  margin-right: 0rem !important;
}

.modal-open[style] {
  padding-right: 0px !important;
}
.features-item {
  width: 100%;
  border-radius: 17px;
}
.features img {
  width: 100%;
  border-radius: 17px;
}
.topcontent p {
  font-size: 19px;
  color: #5cb85c;
}
.topcontent h1 {
  color: #132f6b;
}
.topcontent span {
  color: #fff;
  cursor: pointer;
}
.topcontent i {
  color: white;
}
.topimg img {
  width: 70%;
  border-radius: 17px;
}

.modal-body img {
  width: 50%;
}

.modal-body p i {
  font-size: 50px;
  font-style: tahoma;
  text-align: center;
  width: 100%;
}
.modal-body img {
  width: 100%;
  height: 169px;
}
label {
  font-size: 18px;
}
.spanRed {
  color: red;
}
#successMsg {
  z-index: 9999;
  padding-top: 59px;
}
.modal-dialog {
  z-index: 9999;
}
.modal-content {
  width: auto;
  align-items: center;
  background-color: #f5f5f5;
  color: #0f316d;
  z-index: 9999;
}
.modal-header i {
  font-size: 50px;
  font-style: tahoma;
}
.msg {
  font-size: 20px;
  margin-left: 25px;
}
.modal-header h4 {
  font-size: 18px;
  font-weight: bold;
  width: 100%;
}
.digSignim {
  margin-top: 25%;
  border-radius: 17px;
}
.modal-body img {
  height: 120px;
}
.order-first img {
  margin-top: 0%;
}
.services {
  margin-top: 0%;
}

@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 0px 0 0px 0;
  }
}

.Topbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0px solid red;
}

.topcontent {
  width: 50%;
  border: 0px solid red;
}

.topimg {
  width: 50%;
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.section-header {
  margin-top: 0%;
}

@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 0px 0 0px 0;
  }
}

.Topbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0px solid red;
  margin-top: -10%;
}

.topcontent {
  width: 50%;
  border: 0px solid red;
}

.topimg {
  width: 50%;
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.itrImage {
  width: -moz-available;
  border-radius: 3%;
  width: 70%;
  background-size: auto, auto;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .itrImage {
    width: -moz-available;
    border-radius: 3%;
    width: 70%;
    margin-top: 10%;
    margin-left: 6%;
    background-size: auto, auto;
    background-size: cover;
  }
}

@media screen and (max-width: 991px) {
  .Topbox {
    flex-wrap: wrap;
  }
  .topcontent {
    width: 100%;
    border: 0px solid red;
  }
  .topcontent h1,
  h2 {
    text-align: center;
  }
  .topimg {
    width: 100%;
    border: 0px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

@media (min-width: 1365px) {
  .call-to-action {
    background-attachment: fixed;
  }
}



.modal {
  background-color: #0000008c !important;
  opacity: 1 !important;
} */

.hero {
  height: 100%;
}

/* .page-header {
  background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(static/assets/img/minslider/investmentSlider.jpg) center center no-repeat;
    background-size: auto, auto;
  background-size: cover;
} */

/* .container {
  padding: 20px 0px;
  margin-top: 10px;
} */

.topcontent h1 {
  color: #0f316d;
}
.order-first img {
  border-radius: 17px;
}

.faq .card .card-header .faq-title {
  width: 100%;
  text-align: left;
  padding: 0px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 1px;
  color: #3b566e;
  text-decoration: none !important;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  padding-top: 20px;
  padding-bottom: 20px;
}

.services .card {
  border: 1px solid rgba(14, 29, 52, 0.15);
  background: #fff;
  position: relative;
  border-radius: 17px;
  height: 100%;
}
.display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
}
.me-4 {
  margin-right: 0rem !important;
}

.modal-open[style] {
  padding-right: 0px !important;
}
.features-item {
  width: 100%;
  border-radius: 17px;
}
.features img {
  width: 100%;
  border-radius: 17px;
}
.topcontent p {
  font-size: 19px;
  color: #5cb85c;
}
.topcontent h1 {
  color: #132f6b;
}
.topcontent span {
  color: #fff;
  cursor: pointer;
}
.topcontent i {
  color: white;
}
.topimg img {
  width: 70%;
  /* margin-top: -1%; */
  border-radius: 17px;
}

.modal-body img {
  width: 50%;
}

.modal-body p i {
  font-size: 50px;
  font-style: tahoma;
  text-align: center;
  width: 100%;
}
.modal-body img {
  width: 100%;
  height: 169px;
}
label {
  font-size: 18px;
}
.spanRed {
  color: red;
}
#successMsg {
  z-index: 9999;
  padding-top: 59px;
}
.modal-dialog {
  z-index: 9999;
}
.modal-content {
  width: auto;
  align-items: center;
  background-color: #f5f5f5;
  color: #0f316d;
  z-index: 9999;
}
.modal-header i {
  font-size: 50px;
  font-style: tahoma;
}
.msg {
  font-size: 20px;
  margin-left: 25px;
}
.modal-header h4 {
  font-size: 18px;
  font-weight: bold;
  width: 100%;
}
.digSignim {
  margin-top: 25%;
  border-radius: 17px;
}
.modal-body img {
  height: 120px;
}
.order-first img {
  margin-top: 0%;
}
.services {
  margin-top: 0%;
}
.row {
  padding-top: 0%;
}
.features-service {
  padding-bottom: 0% !important;
}
.faq {
  padding-top: 1%;
}
@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 0px 0 0px 0;
  }
}

.Topbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0px solid red;
}

.topcontent {
  width: 50%;
  border: 0px solid red;
}

.topimg {
  width: 50%;
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.section-header {
  margin-top: 0%;
}

@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 0px 0 0px 0;
  }
  .about {
    padding-bottom: 0% !important;
  }
}

.Topbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0px solid red;
  /* margin-top: -10%; */
}

.topcontent {
  width: 50%;
  border: 0px solid red;
}

.topimg {
  width: 50%;
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.itrImage {
  width: -moz-available;
  border-radius: 3%;
  width: 70%;
  background-size: auto, auto;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .itrImage {
    width: -moz-available;
    border-radius: 3%;
    width: 70%;
    margin-top: 10%;
    margin-left: 6%;
    background-size: auto, auto;
    background-size: cover;
  }
  #hero {
    margin-top: 6%;
  }
}

@media screen and (max-width: 991px) {
  .Topbox {
    flex-wrap: wrap;
  }
  .topcontent {
    width: 100%;
    border: 0px solid red;
  }
  .topcontent h1,
  h2 {
    text-align: center;
  }
  .topimg {
    width: 100%;
    border: 0px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .section-header {
    margin-top: 5%;
  }
}

@media (min-width: 1365px) {
  .call-to-action {
    background-attachment: fixed;
  }
}

/* .call-to-action {
	background: linear-gradient(rgba(14, 29, 52, 0.6), rgba(14, 29, 52, 0.8)),
		url("static/assetse/img/publicLimiredbgCon.jpg") center center;
	background-attachment: scroll, scroll;
	background-size: auto, auto;
	background-size: cover;
	padding: 120px 0px 120px 0px;
} */

@media (max-width: 480px) {
  .section-header {
    margin-top: 10%;
  }
  #frequently {
    padding-top: 0% !important;
  }
  .Topbox {
    margin-top: 10px !important;
  }
}
@media only screen and (max-width: 1024px) {
  #featured-services {
    padding-bottom: 0%;
  }
  .section-header {
    padding-top: 0%;
    padding-bottom: 0%;
  }
  .frequently {
    padding-top: 0%;
  }
  #about {
    padding-bottom: 0%;
  }
}

.modal {
  background-color: #0000008c !important;
  opacity: 1 !important;
}
.btn-close:focus{
  box-shadow: none !important;
}

.card {
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  background-color: white; /* Default background color */
  color: #000; /* Default text color */
  overflow: hidden;
  transition: all 0.3s ease; /* Smooth transition for background color and text color */
  padding: 20px; /* Optional, you can adjust padding as needed */
}


.card-hover:hover {
  background-color: #001f8d;
  color: white; 
}


.card-hover:hover h3, 
.card-hover:hover p {
  color: white !important; 
}

.services .card a:hover{
  color: white !important; 
}
/* .icon-box h4:hover{
color: white !important;
} */

/* .card .colourChange h4:hover{
  color: white !important;
} */


.card-hover:hover h4, 
.card-hover:hover p {
  color: white !important; 
}