@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.blog-header {
  background: #f8f9fa;
  padding: 2rem 0;
}
.blog-post {
  margin-bottom: 2rem;
}
.blog-footer {
  background: #f8f9fa;
  padding: 1rem 0;
  text-align: center;
}

.poppins-regular {
  font-family: "Poppins", serif;
  font-weight: 400;
  font-style: normal;
}
p {
  font-size: 20px;
  color: #333;
  font-weight: 400;
}

h5,
h4 {
  font-family: "Poppins", serif;
  font-optical-sizing: auto;
  font-weight: 200;
}

.newDescription {
  font-family: "Poppins", serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-size: 19px !important;

  padding-left: 10px;
}
.newHeading {
  font-family: "Poppins", serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-size: 28px !important;
}
.newDate {
  padding-left: 10px !important;
}
.para {
  margin-top: 20px;
}

li {
  font-size: 15px;
  color: #333;
  font-weight: 400;
}

b {
  /* font-size: 18px; */
  color: #303e67;
}

.container-fluid {
  margin-left: 0%;
  margin-right: 4%;
}

.heading {
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
h2 {
  color:#0e306d;
  padding: 20px;
  font-size: 30px;
  margin-bottom: 0px;
}
.text-muted {
  color: #6c757d;
  
}

/* b {
      font-weight: bolder;
      font-size: 23px;
    } */

/* li, tr {
      font-size: 18px;
    } */

/* .article-content {
      font-weight: 400;
      font-family: Teko, sans-serif;
    } */

@media screen and (max-width: 991px) {
  .wrapper {
    flex-direction: column;
  }

  .crazy-card {
    width: 100%;
  }
}

/* h3 {
  font-family: "Poppins", serif;
  font-optical-sizing: auto;
  font-weight: 200;
}
p {
  font-family: "Poppins", serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-size: 20px !important;
  padding-left: 10px;
} */

/* Global styles */
.blogs-section {
  padding: 50px 0;
}

.container {
  width: 90%;
  margin: 0 auto;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.section-title {
  color: #5cb85c;
  font-size: 1.5rem;
}

.section-heading {
  color: #132f6b;
  font-size: 2.5rem;
  font-weight: 700;
}

/* Slider */
.blog-card-wrapper {
  padding: 0 15px;
}

.blog-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;  /* Full width */
  height: 419px; /* Set a fixed height for uniformity */
  transition: all 0.3s ease-in-out;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}


.blog-card:hover {
  transform: translateY(-5px);
}

/* Image container */
.image-container {
  position: relative;
  height: 200px;
  overflow: hidden;
}

.image {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
}

.blog-card:hover .image {
  transform: scale(1.1);
}

/* Card Body */

.card-body {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 200px); /* Adjust for the fixed image height */
  padding-bottom: 40px;
}

.card-date {
  font-size: 0.875rem;
  color: #6c757d;
  margin-bottom: 10px;
}

.card-title {
  border: 1px solid transparent;
  height: 50px;
  overflow: hidden;
  padding: 0;
  font-size: 1.25rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 10px;
 
  text-overflow: ellipsis;
}

.card-excerpt {
  font-size: 1rem;
  color: #7f8c8d;
  margin-bottom: 20px;
  flex-grow: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
/* Button */
.btn-read-more {
  align-self: flex-start;
  font-size: 0.875rem;
  padding: 8px 20px;
  color: #3498db;
  border: 2px solid #3498db;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
  margin-top: auto; 
}

.btn-read-more:hover {
  background-color: #3498db;
  color: #fff;
  border-color: #3498db;
}
.image-container {
  position: relative;
  height: 200px; /* Keep this value consistent */
  overflow: hidden;
}


/* Responsive adjustments */
@media (max-width: 1024px) {
  .section-heading {
    font-size: 2rem;
  }

  .blog-card {
    height: auto;
  }
}

@media (max-width: 600px) {
  .section-heading {
    font-size: 1.8rem;
  }

  .card-body {
    padding: 15px;
  }

  .btn-read-more {
    font-size: 0.75rem;
  }
}


/* the Category section Hover */

/* Hover effect for category sidebar */
/* Sidebar hidden by default */
/* Hide the full category list initially */
/* .sidebar-container {
  overflow: hidden;
}

.category-toggle:hover + .category-list {
  display: block;
}

.sidebar-container:hover {
  width: 250px; 
}

.category-list {
  display: none; 
}

.category-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
} */


.category-section{

  position:sticky;
  z-index: 1010;
  top:83px;
  background-color: white;
  width:100%;
  /* justify-content: center;
  display: flex; */

}

.category-text {
  font-size: 18px;
  color: black; /* Default color */
  text-decoration: none; /* Remove underline */
  padding: 8px 16px;
  display: inline-block;
  border-radius: 20px; /* Border radius for rounded edges */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover and click */
  font-weight: 600;
}

.category-text:hover {
  color: #007bff; /* Change text color on hover */
}

.category-text:active {
  background-color: wheat; /* Change background color when clicked */
  color: black; /* Ensure text color stays readable */
}

.active-category {
  color: #007bff; 
 
}



/* the cards glassmorphism */
/* Container for the card details */
.card-details {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Light background overlay */
  color: white !important; /* Text color */
  padding: 10px;
  opacity: 0; /* Initially invisible */
  transform: translateY(100%); /* Initially below the card */
  transition: opacity 0.3s ease, transform 0.3s ease-in-out; /* Smooth transition */
}

/* Hover effect on the card */
.cards:hover .card-details {
  opacity: 1; /* Make it visible */
  transform: translateY(0); /* Slide up to show details */
}

/* Card container for the image */
.cards img {
  transition: transform 0.3s ease-in-out; /* Apply smooth transition for image zoom */
}

/* Card Title */
.card-details .card-title {
  font-size: 18px;
  font-weight: bold;
}

/* Card Date */
.card-details .card-date {
  font-size: 14px;
  margin: 5px 0;
}

/* Read More button styling */
.card-details a {
  font-size: 14px;
  text-decoration: none;
  color: black;
  border: 1px solid transparent; 
  outline: none;
  padding: 5px 15px;
  border-radius: 5px;
  margin-top: 10px;
  background-color: white;
}

.card-details a:hover {
  background-color: wheat;
  color: black;
  border: 1px solid transparent; 
  outline: none;
}

/* Ensure spacing between the cards */
.blog-section .row {
  margin-left: -15px;
  margin-right: -15px;
}

.blog-section .col-md-4 {
  margin-bottom: 30px; /* Add space between cards */
}

/* Optional - Hover effect for image scaling */
/* .card:hover img {
  transform: scale(1.1); 
} */

.text{
  color:white;
  font-weight: 600 !important;
}
.date{
  color: white;
  font-weight: 600 !important;
}


.slick-prev::before, .slick-next::before{
  color:black !important;
  opacity: 1 !important;
  font-size: 30px;
  margin-left: -5px;

}



@media (min-width: 320px) and (max-width: 480px) {
  
  .card-details {
   
    opacity: 1; /* Initially invisible */
    transform: translateY(0); /* Initially below the card */
    border-radius: 0 0 5px 5px;
    background-color: rgba(0, 0, 0, 0.65);
   
  }
  
  /* Hover effect on the card */
 
  /* Card Title */
  .card-details .card-title {
    font-size: 18px;
    font-weight: bold;
  }
  
  /* Card Date */
  .card-details .card-date {
    font-size: 14px;
    margin: 5px 0;
  }
  
  /* Read More button styling */
  .card-title  {
   height: 47px;
  }
  
 
  
  /* Ensure spacing between the cards */
  .blog-section .row {
    margin-left: -15px;
    margin-right: -15px;
  }
  
  .blog-section .col-md-4 {
    margin-bottom: 30px; /* Add space between cards */
  }
  
  /* Optional - Hover effect for image scaling */
  /* .card:hover img {
    transform: scale(1.1); 
  } */
  
  .text{
    color:white;
    font-weight: 600 !important;
  }
  .date{
    color: white;
    font-weight: 600 !important;
  }
  .mobBlog{
    margin-top: -15%;
  
  }
  .mobCard{
    height:375px
  }
  
  
}

@media screen and (max-width: 768px) {  
  .category-section .row {
    display: flex; 
    overflow-x: scroll;
    flex-wrap: nowrap;
    padding-bottom: 10px;
    -webkit-overflow-scrolling: touch;
  }

  .category-section .row {
    scrollbar-width: none;  /* Hide scrollbar in Firefox */
  }

  /* For each category button */
  .categoryButton {
    border: 1px solid gray;
    border-radius: 10px;
    margin-right: 10px;
    display: flex; 
   
  }
  .category-section {
    top: 78px;
  }

  .category-text {
    font-size: 16px; 
    padding: 5px 15px; 
    text-align: center;
    border-radius: 10px;
    display: block; /* Make the link block level to take full width */
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s; 
    width: 100%; 
    font-weight: 600;
  }

  /* When the category button is active */
  .category-text:active,
  .active-category {
    background-color: #0e306d; /* Active background color */
    color: white; /* Active text color */
    margin-top: -1px;
    margin-bottom: -1px;
    margin-left: -1px;
    width: 102%;
    margin-right: -1px;
    
  }
  .row > * {
   
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-top: 0 !important;
  }

  
}

.dropdown-menu li{
  margin-bottom: 0  ;
}



/* Share button */


/* Blog header container with flexbox to align date and share buttons */
.blog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.blog-date {
  font-size: 14px;
  color: #0f2f6a;
}

.share-buttons {
  display: flex;
  gap: 15px;
}

.share-buttons button {
  padding: 10px;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.share-buttons button:hover {
  background-color: #0056b3;
}
.share-buttons .fa-icon {
  font-size: 2rem; /* Default size for larger screens */
  /* margin-top: 10px; */
}

/* CSS for mobile screens */
@media (max-width: 767px) {
  .share-buttons .fa-icon {
    font-size: 25px; /* Smaller icons for mobile */
    margin-top: 0;
  }
  .share-buttons{
    gap: 5px;
    margin-right: 10px;
  }
  .text-muted{
    font-size: 14px;
    margin-left: 10px !important;
  }
}