/* .hero {
  margin-top: -5%;
}
.me-4 {
  margin-right: 0rem !important;
}
.about {
  display: flex;
  justify-content: center;
  align-items: center;
}
#aboutId p {
  text-align: justify;
  line-height: 22px;
}
.content p {
  line-height: 22px;
}
#ImageImport img {
  margin-top: 20%;
  border-radius: 17px;
}
#about .ImpImg {
  border-radius: 17px;
}

.features-item h3 {
  color: #001973;
}
label {
  font-size: 14px;
}
#successMsg {
  z-index: 9999;
  padding-top: 59px;
}
.model-header i {
  font-size: 50px;
  font-style: tahoma;
}
.model-header h4 {
  color: green;
}
.msg {
  font-size: 20px;
  margin-left: 25px;
}
.modal-footer button {
  font-size: 18px;
}
.modal-content {
  width: auto;
  align-items: center;
  background-color: #f5f5f5;
  color: #0f316d;
  z-index: 9999;
}
.modal-titel {
  font-size: 18px;
  font-weight: bold;
  width: 100%;
}
.modal-body i {
  font-size: 50px;
  font-style: tahoma;
  text-align: center;
  width: 100%;
}
.modal-body img {
  width: 100%;
  height: 169px;
}
.container h3 {
  color: 001973;
}
.model-dialog {
  z-index: 9999;
}
.order-last p {
  text-align: justify;
}
.feture-code {
  color: #001973;
}
.submitBtn {
  text-align: center;
}
.topcontent {
  color: green;
  margin-top: 0%;
}
.topcontent p{
  font-size: 19px;
}
.iecImgTop {
  width: 100%;
  margin-top: -1%;
}
.me-4 {
  margin-right: 0rem !important;
}
@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 0px 0 0px 0;
  }
}

.Topbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0px solid red;
}

.topcontent {
  width: 50%;
  border: 0px solid red;
}

.features {
  padding-top: 0px;
}

.topimg {
  width: 50%;
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media screen and (max-width: 991px) {
  .Topbox {
    flex-wrap: wrap;
  }
  .topcontent {
    width: 100%;
    border: 0px solid red;
  }
  .topcontent h1,
  h2 {
    text-align: center;
  }
  .topimg {
    width: 100%;
    border: 0px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

@media (min-width: 1365px) {
  .call-to-action {
    background-attachment: fixed;
  }
}
.iecImage {
  width: -moz-available;
  border-radius: 3%;
  width: 100%;
  margin-top: -6%;
  background-size: auto, auto;
  background-size: cover;
}

@media (max-width: 768px) {
  .whatTop {
    margin-top: 0%;
  }
  .whatTopApp {
    margin-top: 0%;
  }

  .iecImage {
    width: -moz-available;
    border-radius: 3%;
    width: 100%;
    margin-top: 6%;
    background-size: auto, auto;
    background-size: cover;
  }

  .iecImgTop {
    width: 100%;
    margin-top: 6%;
  }
}

.modal {
  background-color: #0000008c !important;
  opacity: 1 !important;
}

.modal-open[style] {
  padding-right: 0px !important;
} */

.hero {
  margin-top: -5%;
}
/* .container {
  margin-top: 45px;
} */
.me-4 {
  margin-right: 0rem !important;
}
.about {
  display: flex;
  justify-content: center;
  align-items: center;
}
#aboutId p {
  text-align: justify;
  /* font-size: 14px; */
  line-height: 22px;
}
.content p {
  line-height: 22px;
}
#ImageImport img {
  margin-top: 20%;
  border-radius: 17px;
}
#about .ImpImg {
  border-radius: 17px;
}

.features-item h3 {
  color: #001973;
}
label {
  font-size: 14px;
}
#successMsg {
  z-index: 9999;
  padding-top: 59px;
}
.model-header i {
  font-size: 50px;
  font-style: tahoma;
}
.model-header h4 {
  color: green;
}
.msg {
  font-size: 20px;
  margin-left: 25px;
}
.modal-footer button {
  font-size: 18px;
}
.modal-content {
  width: auto;
  align-items: center;
  background-color: #f5f5f5;
  color: #0f316d;
  z-index: 9999;
}
.modal-titel {
  font-size: 18px;
  font-weight: bold;
  width: 100%;
}
.modal-body i {
  font-size: 50px;
  font-style: tahoma;
  text-align: center;
  width: 100%;
}
.modal-body img {
  width: 100%;
  height: 169px;
}
.container h3 {
  margin-left: 10px;
}
.model-dialog {
  z-index: 9999;
}
.order-last p {
  text-align: justify;
}
.feture-code {
  color: #001973;
}
.submitBtn {
  text-align: center;
}
.topcontent {
  color: green;
  margin-top: 0%;
}
.topcontent p {
  font-size: 19px;
}
/* .display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
} */
/* .page-header {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/hero-bg.jpg") center center no-repeat;
  background-size: auto, auto;
  background-size: cover;
} */
.iecImgTop {
  width: 100%;
  margin-top: -1%;
}
.me-4 {
  margin-right: 0rem !important;
}
@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 0px 0 0px 0;
  }
}

.Topbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0px solid red;
}

.topcontent {
  width: 50%;
  border: 0px solid red;
}

.features {
  padding-top: 0px;
}

.topimg {
  width: 50%;
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media screen and (max-width: 991px) {
  .Topbox {
    flex-wrap: wrap;
  }
  .topcontent {
    width: 100%;
    border: 0px solid red;
  }
  /* .topcontent #resp {
    margin-left: 35%;
  } */
  .topcontent h1,
  h2 {
    text-align: center;
  }
  .topimg {
    width: 100%;
    border: 0px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  #ImageImport img {
    margin-top: 0%;
    padding-top: 0%;
  }
}

@media (min-width: 1365px) {
  .call-to-action {
    background-attachment: fixed;
  }
}

/* .call-to-action {
  background: linear-gradient(rgba(14, 29, 52, 0.6), rgba(14, 29, 52, 0.8)),
    url("../assets/hero-bg.jpg") center center;
  background-attachment: scroll, scroll;
  background-size: auto, auto;
  background-size: cover;
  padding: 120px 0px 120px 0px;
} */

.iecImage {
  width: -moz-available;
  border-radius: 3%;
  width: 100%;
  margin-top: -6%;
  background-size: auto, auto;
  background-size: cover;
}

@media (max-width: 768px) {
  .whatTop {
    margin-top: 0%;
  }
  .whatTopApp {
    margin-top: 0%;
  }

  .iecImage {
    width: -moz-available;
    border-radius: 3%;
    width: 100%;
    margin-top: 6%;
    background-size: auto, auto;
    background-size: cover;
  }

  .iecImgTop {
    width: 100%;
    margin-top: 6%;
  }
}

.modal {
  background-color: #0000008c !important;
  opacity: 1 !important;
}

.modal-open[style] {
  padding-right: 0px !important;
}

@media (max-width: 480px) {
  #hero {
    margin-top: -20%;
  }
  .topcontent #resp {
    margin-top: -6%;
  }
  #ImageImport img {
    margin-top: -7%;
  }
  .container {
    margin-top: 0%;
    margin-bottom: 0%;
  }
  .Topbox {
    margin-top: 0%;
  }
  .resps {
    padding-top: 0%;
  }
  .section-header h2 {
    margin-top: -10%;
    padding-top: 0%;
    padding-bottom: 0%;
    font-size: 30px;
  }
  .about .content {
    padding-bottom: 0%;
  }
  .align-self-center {
    margin-top: -10%;
  }
  .features {
    margin-top: 0%;
    margin-bottom: 0%;
  }
  .whatTopApp {
    margin: 0%;
    padding-top: 0%;
  }
  .topimg {
    padding-top: -10%;
  }
}
