/* 
.hero {
	height: 100%;
}

.gstTop{
	margin-top:0%;
}
.mtop-8{
	margin-top:-8%;
}

.sectionTop{
	margin-top: 6%;
}

.freColor{
	color:#0e306d;
}

.section-header {
	text-align: center;
	padding: 30px 0;
	position: relative;
	z-index: 0;
  }
  .section-header {
	text-align: center;
	padding-bottom: 20px;
  }
  .section-header {
	text-align: center;
	padding: 30px 0;
	position: relative;
	z-index: 0;
  }
  .section-header {
	text-align: center;
	padding-bottom: 20px;
  }


  .section-header h2 {
	font-size: 32px;
	font-weight: 700;
	text-transform: uppercase;
	margin-bottom: 20px;
	padding-bottom: 0;
	color: #001973;
	position: relative;
	z-index: 2;
	margin-bottom: 20px;
	padding-bottom: 20px;
	position: relative;
  }
  .section-header h2 {
	font-size: 13px;
	letter-spacing: 1px;
	font-weight: 700;
	margin: 0;
	  margin-bottom: 0px;
	color: #4154f1;
	text-transform: uppercase;
  }
  .section-header h2 {
	font-size: 32px;
	font-weight: 700;
	text-transform: uppercase;
	margin-bottom: 20px;
	padding-bottom: 0;
	color: #001973;
	position: relative;
	z-index: 2;
	margin-bottom: 20px;
	padding-bottom: 20px;
	position: relative;
  }
  .section-header h2 {
	font-size: 13px;
	letter-spacing: 1px;
	font-weight: 700;
	margin: 0;
	color: #4154f1;
	text-transform: uppercase;
  }
  h1, h2, .h1, .h2, .fw-bold {
	font-weight: 600 !important;
  }
  @media (min-width: 1200px) {
	h2, .h2 {
	  font-size: 2rem;
	}
  }

.tdsimg{
	width:100%;height:450px;
}
.me-4 {
  margin-right: 0rem !important;
}

.features .feature-box h3 {
  font-size: 22px;
  color: #012970;
  font-weight: 700;
  margin: 0;
}
.faq .card .card-header .faq-title {
  width: 100%;
  text-align: left;
  padding: 0px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 1px;
  color: #3B566E;
  text-decoration: none !important;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media ( max-width : 991px) {
	.hero {
		height: auto;
		padding: 0px 0 0px 0;
	}
}
.Topbox {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	border: 0px solid red;
}

.topcontent {
	width: 50%;
	border: 0px solid red;
	margin-top: 21%;
}

.topimg {
	width: 50%;
	border: 0px solid red;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

@media screen and (max-width:991px) {
	.Topbox {
		flex-wrap: wrap;
		flex-direction: column-reverse;
	}
	.topcontent {
		width: 100%;
		border: 0px solid red;
		margin-bottom: 20px !important;
	}
	.topcontent h1, h2 {
		text-align: center;
	}
	.topimg {
		width: 100%;
		border: 0px solid red;
		display: flex;
		align-items: center;
		justify-content: space-around;
	}
}

.top5{
    margin-top: -5%;
}

.InfoColor{
    color:#132f6b;
}
.getTouch{
    color: #fff; cursor: pointer;
}
.white{
    color:#fff;
}
.gstImageCss{
    width: 70%;margin-top: 38%;
}
.ourServiceColor{
	color:#5cb85c
}

.gstTopClass{
	margin-top:-7%;
}

.gstColorInvoice{
	color:#132f6b;
}
.stepsColor{
	color:#5cb85c;
}
.stepsInvoiceColor{
	color:#132f6b;
}

.ourService{
	max-width: 600px;
}
.about p {
	margin: 15px 0 30px 0;
	line-height: 40px;
}
.effort{
    font-size: 19px;color:#5cb85c;
}

section {
	padding: 0px 0;
	overflow: hidden;
}

.modal {
	background-color: #0000008c !important;
}

.display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
}

.mui-card {
    background: #fff;
  border-radius: 2px;
  display: inline-block;
  height: 300px;
  margin: 1rem;
  position: relative;
  width: 300px;
}



  
  .mui-card {
	  background: #fff;
	border-radius: 2px;
	display: inline-block;
	height: auto;
	margin: 1rem;
	position: relative;
	width: 90%;
	margin-left: 7%;
  }
  
  .xs-shadow {
	  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  }
  
  .xs-shadow:hover {
	box-shadow: 0 14px 28px
	rgba(0,0,0,0.25), 0 10px 10px
	rgba(0,0,0,0.22);
  }
  
  .xm-shadow {
	  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }
  
  .xm-shadow:hover {
	  box-shadow: 0 14px 28px
	rgba(0,0,0,0.25), 0 10px 10px
	rgba(0,0,0,0.22);
  }
  
  .md-shadow {
	  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }
  
  .md-shadow:hover {
	box-shadow: 0 14px 28px
	rgba(0,0,0,0.25), 0 10px 10px
	rgba(0,0,0,0.22);
  }
  
  .xl-shadow {
	  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
  
  .xl-shadow:hover {
		box-shadow: 0 14px 28px
	rgba(0,0,0,0.25), 0 10px 10px
	rgba(0,0,0,0.22);
  }
  
  .xxl-shadow {
	  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  }
  
  .xxl-shadow:hover {
		box-shadow: 0 14px 28px
	rgba(0,0,0,0.25), 0 10px 10px
	rgba(0,0,0,0.22);
  }


.accordion-button:not(.collapsed),
.accordion-button:focus {
  outline: none;
  border-color: transparent;
  box-shadow: none;
  background-color: transparent;
}
.accordion-button::after {
  width: 11px;
  height: 11px;
  border-radius: 100%;
  background-color: var(--bs-danger);
  background-image: none !important;
}
.accordion-button.collapsed::after {
  background-color: var(--bs-gray-300);
}






@media (max-width: 768px) { 
	.top0{
		margin-top: -11%
	}
  } */

.hero {
  height: 100%;
}

.gstTop {
  margin-top: 0%;
}
.mtop-8 {
  margin-top: -8%;
}

.sectionTop {
  margin-top: 6%;
}

.freColor {
  color: #0e306d;
}

.section-header {
  text-align: center;
  padding: 30px 0;
  position: relative;
  z-index: 0;
}
.section-header {
  text-align: center;
  padding-bottom: 20px;
}
.section-header {
  text-align: center;
  padding: 30px 0;
  position: relative;
  z-index: 0;
}
.section-header {
  text-align: center;
  padding-bottom: 20px;
  margin-top: 7% !important;
}

.section-header h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #001973;
  position: relative;
  z-index: 2;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}
.section-header h2 {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 0px;
  color: #4154f1;
  text-transform: uppercase;
}
.section-header h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #001973;
  position: relative;
  z-index: 2;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}
.section-header h2 {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  margin: 0;
  color: #4154f1;
  text-transform: uppercase;
}
h1,
h2,
.h1,
.h2,
.fw-bold {
  font-weight: 600 !important;
}
@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 2rem;
  }
}

.tdsimg {
  width: 100%;
  height: 450px;
}
.me-4 {
  margin-right: 0rem !important;
}

.features .feature-box h3 {
  font-size: 22px;
  color: #012970;
  font-weight: 700;
  margin: 0;
}
.faq .card .card-header .faq-title {
  width: 100%;
  text-align: left;
  padding: 0px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 1px;
  color: #3b566e;
  text-decoration: none !important;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 0px 0 0px 0;
  }
}

/* @media screen and (max-width:1350px) {
	  section {
		  padding: 0px 0;
	  }
  }
   */
.Topbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0px solid red;
}

.topcontent {
  width: 50%;
  border: 0px solid red;
  margin-top: 21%;
}

.topimg {
  width: 50%;
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media screen and (max-width: 991px) {
  .Topbox {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .topcontent {
    width: 100%;
    border: 0px solid red;
    margin-bottom: 20px !important;
  }
  .topcontent h1,
  h2 {
    text-align: center;
  }
  .topimg {
    width: 100%;
    border: 0px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.top5 {
  margin-top: -5%;
}
/* .top0 {
  margin-top: -11%;
} */
.InfoColor {
  color: #132f6b;
}
.getTouch {
  color: #fff;
  cursor: pointer;
}
.white {
  color: #fff;
}
.gstImageCss {
  width: 70%;
  margin-top: 38%;
}
.ourServiceColor {
  color: #5cb85c;
}

.gstTopClass {
  margin-top: -7%;
}

.gstColorInvoice {
  color: #132f6b;
}
.stepsColor {
  color: #5cb85c;
}
.stepsInvoiceColor {
  color: #132f6b;
}

.ourService {
  max-width: 600px;
}
.about p {
  margin: 15px 0 30px 0;
  line-height: 40px;
}
.effort {
  font-size: 19px;
  color: #5cb85c;
}

section {
  padding: 0px 0;
  overflow: hidden;
}

.modal {
  background-color: #0000008c !important;
}

.display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
}

.mui-card {
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  height: 300px;
  margin: 1rem;
  position: relative;
  width: 300px;
}

.mui-card {
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  height: auto;
  margin: 1rem;
  position: relative;
  width: 90%;
  margin-left: 7%;
}

.xs-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.xs-shadow:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.xm-shadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.xm-shadow:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.md-shadow {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.md-shadow:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.xl-shadow {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.xl-shadow:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.xxl-shadow {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.xxl-shadow:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.accordion-button:not(.collapsed),
.accordion-button:focus {
  outline: none;
  border-color: transparent;
  box-shadow: none;
  background-color: transparent;
}
.accordion-button::after {
  width: 11px;
  height: 11px;
  border-radius: 100%;
  background-color: var(--bs-danger);
  background-image: none !important;
}
.accordion-button.collapsed::after {
  background-color: var(--bs-gray-300);
}

@media (max-width: 480px) {
  .features .container {
    padding-top: 10%;
  }
  .section-header h2 {
    margin-top: 10% !important;
  }
}
@media (max-width: 768px) {
  #hero {
    padding-top: 6% !important;
  }
}
@media (max-width: 480px) {
  #hero {
    padding-top: 24% !important;
  }
  .whyRecon {
    margin-top: 0% !important;
  }
}
