.hero {
  height: 100%;

  /* padding-bottom: 3%; */
}

.display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
}
.Topbox {
  padding-top: 0% !important;
}
.me-4 {
  margin-right: 0rem !important;
}

.bulkPanImageCss {
  width: 100%;
  margin-top: 27%;
}

/* .whatTop{
  margin-top:-5%;
} */
section {
  padding: 48px 0;
  overflow: hidden;
}

@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 0px 0 0px 0;
  }
}

/* @media screen and (max-width:1350px) {
	section {
		padding: 0px 0;
	}
}
 */
.Topbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0px solid red;
  margin-top: 5%;
}

.topcontent {
  width: 50%;
  border: 0px solid red;
}

.topimg {
  width: 50%;
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media screen and (max-width: 991px) {
  .Topbox {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .topcontent {
    width: 100%;
    border: 0px solid red;
    margin-bottom: 20px;
  }
  .topcontent h1,
  h2 {
    text-align: center;
  }
  .topimg {
    width: 100%;
    border: 0px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

@media (max-width: 768px) {
  .whatTop {
    margin-top: -10%;
  }
  .slideInDown {
    padding-top: 6%;
  }
  .whyRecon {
    margin-top: 0% !important;
  }
}
@media (max-width: 480px) {
  .effort {
    padding-bottom: 0% !important;
  }
  .whyRecon {
    margin-top: 0% !important;
  }
}

.modal {
  background-color: #0000008c !important;
}


li{
  color: black !important;
}