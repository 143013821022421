.text-body {
  color: #fff !important;
}

.navFont {
  color: black;
  font-weight: bold;
}


.dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}

.modal-open[style] {
  padding-right: 0px !important;
}

.modal {
  overflow-y: auto;
}

.bgColor {
  background-color: #0f316d;
}

.modal-open {
  overflow: auto;
}

.navTop {
  margin-top: 0%;
}

.footerCss {
  background-color: #0e306d;
  color: #fff;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
  margin-top: -3%;
}

.navbar .dropdown-toggles::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
  margin-top: -14%;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #fff;
  margin-right: 10px;
}

.fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  display: block ruby;
}

.offcanvas-header {
  display: none;
}

.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
  margin-left: auto;
  margin-left: 94%;
}

.screen-overlay {
  width: 0%;
  height: 100%;
  z-index: 30;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(34, 34, 34, 0.6);
  transition: opacity 0.2s linear, visibility 0.1s, width 1s ease-in;
}
.screen-overlay.show {
  transition: opacity 0.5s ease, width 0s;
  opacity: 1;
  width: 100%;
  visibility: visible;
}

@media all and (max-width: 992px) {
  .offcanvas-header {
    display: block;
  }

  .mobile-offcanvas {
    visibility: hidden;
    transform: translateX(-100%);
    border-radius: 0;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1200;
    width: 80%;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
    background-color: #fff !important;
  }

  html:lang(ar) .mobile-offcanvas {
    transform: translateX(100%);
  }

  .nav-link {
    display: block;
    padding: 0.5rem 1rem;

    transition: color 0.15s ease-in-out; 
    background-color : 0.15s ease-in-out;
    border-color: 0.15s ease-in-out;
    color: black !important;
    text-transform: none;
  }

  .mobile-offcanvas.show {
    visibility: visible;
    transform: translateX(0);
  }

  .nav-cont {
    display: block !important;
    padding-right: auto !important;
    padding-left: auto !important;
  }

  .navbar2 {
    box-shadow: 0px 3px 17px #00000047;
  }
  #navbar_main {
    box-shadow: 0px 3px 17px #00000047;
  }
  .dropdown-menu {
    border: none;
  }
  .nav-item {
    border-bottom: 1px solid #eaeaea;
  }
}

@media screen and (max-width: 768px) {
  .navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
    margin-top: -1%;
  }

  .mobileNavbar {
    visibility: visible;
    animation-delay: 0.1s;
    top: 28px;
  }
}

@media (min-width: 300px) and (max-width: 700px) {
  .mb {
    display: none;
  }

  .navbar-brand {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: -7rem;
    font-size: 1.25rem;
    white-space: nowrap;
  }

  .navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin: 8px;
    float: inline-end;
  }
}

@media only screen and (min-width: 700px) {
  .hide-on-desktop,
  * [aria-labelledby="hide-on-desktop"] {
    display: none;
  }
}

/* Ipad MINI View */

@media (min-width: 768px) and (max-width: 1024px) {
  .mb {
    display: none;
  }

  .ipadMargin {
    margin-left: 60%;
  }
}

@media (min-width: 820px) and (max-width: 1180px) {
  .mb {
    display: none;
  }

  .ipadMargin {
    margin-left: 60%;
  }

  .navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 155px;
    float: right;
    margin: 0;
  }
}

@media (max-width: 1279px) {
  .mediaView {
    display: none;
    top: 0;
    right: -100%;
    width: 100%;
    max-width: 0px;
    bottom: 0;
    transition: 0.3s;
    z-index: 9997;
  }
}

@media (max-width: 768px) {
  /* Adjust the max-width as needed */
  .hide-on-mobile {
    display: none;
  }
  .mediaNavbarView {
    margin-top: -22%;
  }
}

/* @media (max-width: 480px) {
  .mediaNavbarView {
    margin-top: -21%;
  }
} */





.navbar-nav .nav-item .nav-link:hover {
  color: #406aff !important;   
  transition: color 0.3s ease;
}

.navbar-nav .nav-item .dropdown-toggle:hover {
  color: #406aff !important;   
  transition: color 0.3s ease;
}



.mobile-navbar {
  margin-top: 0 !important;  /* Ensure the navbar is aligned at the top */
  padding-top: 0;  /* Optional: Removes extra padding */
  position: fixed !important;  /* Fixes the navbar at the top */
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;  /* Ensures it stays on top of other content */
}

.navbar.sticky-top{
  top: 0px !important;
}

@media (max-width:480px) {
  .navbar-nav .dropdown-menu {
  white-space: normal;  
  word-wrap: break-word; 
  overflow-wrap: break-word;
  width: 100%;
}

.navbar-nav .dropdown-item {
  white-space: normal;  
}
}

.btn:active{
  border: none !important;
  color: none !important;
  background-color: none !important;
  border-color: none !important;
}