/* 
.hero {
	height: 100%;
}

.display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
}

.modal-open[style] {
padding-right: 0px !important;
}

.modal {
 overflow-y: auto;
}

.modal-open {
 overflow: auto;
}


.me-4 {
  margin-right: 0rem !important;
}

 .page-header {
  background-size: auto, auto;
  background-size: cover;
} 



#hero {
	width: 100%;
	position: relative;
	
	background-size: auto;
	background-size: cover;
	padding: 120px 0 120px 0;
}


.features {
	padding-top: 27px;
}

@media ( max-width : 991px) {
	.hero {
		height: auto;
		padding: 0px 0 0px 0;
	}
}


.Topbox {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	border: 0px solid red;
}

.topcontent {
	width: 50%;
	border: 0px solid red;
}

.topimg {
	width: 50%;
	border: 0px solid red;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

@media screen and (max-width:991px) {
	.Topbox {
		flex-wrap: wrap;
		flex-direction: column-reverse;
	}
	.topcontent {
		width: 100%;
		border: 0px solid red;
		margin-bottom: 20px;
	}
	.topcontent h1, h2 {
		text-align: center;
	}
	.topimg {
		width: 100%;
		border: 0px solid red;
		display: flex;
		align-items: center;
		justify-content: space-around;
	}
}

.about p {
	margin: 15px 0 30px 0;
	line-height: 40px;
}

.llpTop {
	margin-top: 0%;
}
@media screen and (max-width: 768px) {
	.llpTop {
		margin-top: 0%;
	}
	.featuresTop {
		margin-top: 0%;
	}
	.featuresIncludeTop {
		margin-top: 0%;
	}
}

.hero {
	min-height: 100%;
}

.modal {
	background-color: #0000008c !important;
} */

.hero {
  height: 100%;
}

.display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
}

.modal-open[style] {
  padding-right: 0px !important;
}

.modal {
  overflow-y: auto;
}

.modal-open {
  overflow: auto;
}

.me-4 {
  margin-right: 0rem !important;
}

.page-header {
  background-size: auto, auto;
  background-size: cover;
}

#hero {
  width: 100%;
  position: relative;

  background-size: auto;
  background-size: cover;
  padding: 120px 0 0px 0;
}

/* .featuresTop{
	margin-top:-5%;
  } */
.features {
  padding-top: 27px;
}

@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 0px 0 0px 0;
  }
  .features-item {
    margin-top: 1% !important;
  }
  .section-header {
    margin-top: 0% !important;
    padding-top: 1% !important;
    padding-bottom: 1% !important;
  }
}

/* @media screen and (max-width:1350px) {
	  section {
		  padding: 0px 0;
	  }
  } */
.Topbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0px solid red;
}

.topcontent {
  width: 50%;
  border: 0px solid red;
}

.topimg {
  width: 50%;
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media screen and (max-width: 991px) {
  .Topbox {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .topcontent {
    width: 100%;
    border: 0px solid red;
    margin-bottom: 20px;
  }
  .topcontent h1,
  h2 {
    text-align: center;
  }
  .topimg {
    width: 100%;
    border: 0px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.about p {
  margin: 15px 0 30px 0;
  line-height: 40px;
}

.llpTop {
  margin-top: 0%;
}

/*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  .llpTop {
    margin-top: 0%;
  }
  .featuresTop {
    margin-top: 0%;
  }
  .featuresIncludeTop {
    margin-top: 0%;
  }
  .whyRecon {
    margin-top: 0% !important;
  }
}

.hero {
  min-height: 100%;
}

.modal {
  background-color: #0000008c !important;
}

@media (max-width: 480px) {
  .img-fluid {
    margin-top: 1% !important;
  }
  .whyRecon {
    margin-top: 0% !important;
  }
}
