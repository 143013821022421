/* .nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 17px;
}

.marqueeTop {
  margin-top: 0%;
}

.importantCss {
  color: #fff;
  margin-top: 3%;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;

  flex: 0 1 auto;

  width: 30px;

  height: 3px;

  padding: 0;

  margin-right: 3px;

  margin-left: 3px;

  text-indent: -999px;

  cursor: pointer;

  background-color: red;

  background-clip: padding-box;

  border: 0;

  border-top: 10px solid transparent;

  border-bottom: 10px solid transparent;

  opacity: 0.5;

  transition: opacity 0.6s ease;
}

.ticker {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 50px;
  margin: 0 auto;
}

.title {
  width: 100%;
  text-align: start;
  position: relative;
  font-size: 32px;
}

.news {
  width: 80%;
  background: #012970;
  padding: 0 2%;
}

.tdsFonts {
  margin: 0;
  font-size: 59px;
  font-weight: 700;
  color: #012970;
  display: block ruby;
}

.tdsFontss {
  margin-top: 2%;
  font-size: 59px;
  font-weight: 700;
  color: #012970;
}

.carouselMargin {
  margin-left: 50%;
}

.getCss {
  color: #fff;
  cursor: pointer;
}
.whiteCss {
  color: #fff;
}

.btn-get-started {
  margin-top: 30px;
  line-height: 0;
  padding: 15px 40px;
  border-radius: 17px;
  transition: 0.5s;
  font-size: 19px;
  color: #fff;
  background: #4154f1;
  box-shadow: 0px 5px 30px rgba(65, 84, 241, 0.4);
}
.btn-get-started {
  margin-top: 30px;
  line-height: 0;
  padding: 15px 40px;
  border-radius: 17px;
  transition: 0.5s;
  color: #fff;
  background: #4154f1;
  box-shadow: 0px 5px 30px rgba(65, 84, 241, 0.4);
}
.tdsButton {
  margin-left: 19%;
}
.align-self-center {
  align-self: center !important;
}
.align-items-center {
  align-items: center !important;
}
.justify-content-center {
  justify-content: center !important;
}
.d-inline-flex {
  display: inline-flex !important;
}

.readColor {
  background-color: #5cb85c;
}

.project .nav .nav-link {
  background: var(--light);
  transition: 0.5s;
}

.text {
  content: Software;
}

.project .nav .nav-link.active h3 {
  color: #ffffff !important;
}

.project .nav .nav-link.active {
  background: #0f306c;
  border-radius: 17px !important;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 8.25rem;
  left: 70%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;

  text-align: center;
  font-size: 4rem;
  color: #0f316d;
}

.carousel-caption p {
  margin-left: 70%;
  font-size: 59px;
  font-weight: 700;
  color: #012970;
  display: block ruby;
  margin-top: -22%;
  font-family: "Teko", sans-serif;
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-left: 3%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

.img-fluids {
  max-width: 100%;
  height: 360px;
  margin-left: 15%;
  margin-top: 22%;
  border-radius: 17px;
}

.gstImageStyle {
  height: 455px;
  width: 100%;
}

.topClass {
  margin-top: 0px;
}
.colorWhite {
  color: #fff;
}
.gstH1 {
  margin-top: -2%;
  margin-left: -8%;
}
.gstButton {
  color: #fff;
  cursor: pointer;
}

.invoiceImagecss {
  height: 455px;
  width: 100%;
}
.mr13 {
  margin-left: -13%;
}
.invoiceButtonCss {
  color: #fff;
  cursor: pointer;
}
.investCss {
  height: 455px;
  width: 100%;
}

.tdsSpan {
  color: #fff;
  cursor: pointer;
}

.slide-container .prev:hover,
.slide-container .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Topbox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 0px solid red;
    margin-top: 9%;
  }
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: red;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

.modal {
  background-color: #0000008c !important;
  opacity: 1 !important;
}
.project .nav .nav-link.active {
  background: #0f306c;
}

.fact-item:hover .fact-icon {
  background: #0f306c;
}

.owl-carousel-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}

.img-fluids {
  max-width: 100%;
  height: 360px;
  margin-left: 15%;
  margin-top: 22%;
}

.imageClass {
  height: 455px;
  width: 100%;
}

.carouselFont {
  margin-left: -9%;
}

@media screen and (max-width: 768px) {
  .imageClass {
    height: 455px;
    width: 100%;
  }

  .tdsImage {
    width: 75%;
    margin-top: -1%;
    margin-left: -10%;
  }

  .carouselFont {
    margin-left: 0%;
  }

  .tdsButton {
    margin-left: -7%;
  }

  .hero h1 {
    margin: 0;
    font-size: 45px;
    font-weight: 700;
    color: #012970;
  }

  .tdsmargin {
    padding-left: 0%;
  }

  .invoiceMargin {
    padding-left: 8%;
  }

  .gstReconMargin {
    padding-left: 4%;
  }
  .title:after {
    position: absolute;
    content: "";
    right: -50%;
    border-left: 20px solid #5cb85c;
    border-top: 28px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 21px solid transparent;
    top: 8px;
  }
}
.mr2 {
  margin-top: -2%;
}
.smallText {
  color: #5cb85c;
}
.height55 {
  height: 55px;
  width: 258px;
}
.maxWidth {
  max-width: 600px;
}
.border-17 {
  border-radius: 17px;
}

.styleClass {
  width: 65px;
  height: 65px;
}

.black {
  color: black;
}
.widthCss {
  width: 65px;
  height: 65px;
}

.fact-item .fact-icon {
  width: 120px;
  height: 120px;
  margin-top: -60px;
  margin-bottom: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 120px;
  transition: 0.5s;
}

.legalColor {
  color: #0e306d;
}
.contactUs {
  color: #5cb85c;
}
.borderRad {
  border-radius: 62px;
}
.reasonColor {
  color: #5cb85c;
}
.licenceColor {
  color: #0e306d;
}
.subColor {
  color: #0e306d;
}

.dateCss {
  color: #fff;
  margin-top: 3%;
}

@media (max-width: 991px) {
  .feature-img .forThe {
    margin-top: 10% !important;
  }
  #padd {
    width: 220px;
  }
}

@media (max-width: 768px) {
  .feature-img .forThe {
    margin-top: 10% !important;
  }
}

@media (max-width: 480px) {
  .container-xxl h1 {
    font-size: 20px !important;
  }
  .reasonColor {
    margin-top: 1% !important;
  }
  .feature-img .forThe {
    margin-top: 12% !important;
  }
  .subColor {
    font-size: 25px !important;
  }
  .legalColor {
    font-size: 25px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .carousel {
    height: 35vh;
  }

  .ticker {
    margin-top: 5%;
  }
  .project {
    padding-bottom: 0% !important;
  }
  #btnWidth {
    width: 220px;
  }
  .smallText {
    font-size: 30px;
  }
  .legalColor {
    padding-bottom: 4%;
  }
} */

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 17px;
}

.marqueeTop {
  /* margin-top: 0%; */
  margin-bottom: 4%;
}

.importantCss {
  color: #fff;
  padding-left: 20% !important;
  /* width: 20%; */
  margin-top: 3% !important;
}

.ticker {
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
  height: 50px;
  margin: 0 auto;
}

.title {
  width: 20% !important;
  text-align: start;
  background: #47a047 !important;
  position: relative;
}
/* .ticker {
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  background-color: red;
} */

.news {
  width: 80%;
  background: #012970;
  padding: 0 2%;
}

.tdsFonts {
  margin: 0;
  font-size: 59px;
  font-weight: 700;
  color: #012970;
  display: block ruby;
}

.tdsFontss {
  margin-top: 2%;
  font-size: 59px;
  font-weight: 700;
  color: #012970;
}

.carouselMargin {
  margin-left: 50%;
}

.getCss {
  color: #fff;
  cursor: pointer;
}
.whiteCss {
  color: #fff;
}

.btn-get-started {
  margin-top: 30px;
  line-height: 0;
  padding: 15px 40px;
  border-radius: 11px;
  transition: 0.5s;
  font-size: 19px;
  color: #fff;
  background: #4154f1;
  box-shadow: 0px 5px 30px rgba(65, 84, 241, 0.4);
}

.tdsButton {
  margin-left: 19%;
}
.align-self-center {
  align-self: center !important;
}
.align-items-center {
  align-items: center !important;
}
.justify-content-center {
  justify-content: center !important;
}
.d-inline-flex {
  display: inline-flex !important;
}

.readColor {
  background-color: #5cb85c;
}

.project .nav .nav-link {
  background: var(--light);
  transition: 0.5s;
}

.text {
  content: Software;
}

.project .nav .nav-link.active h3 {
  color: #ffffff !important;
}

.project .nav .nav-link.active {
  background: #0f306c;
  border-radius: 17px !important;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 8.25rem;
  left: 70%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;

  text-align: center;
  font-size: 4rem;
  color: #0f316d;
}

.carousel-caption p {
  margin-left: 70%;
  font-size: 59px;
  font-weight: 700;
  color: #012970;
  display: block ruby;
  margin-top: -22%;
  font-family: "Teko", sans-serif;
}
/* .carousel-caption h3{
  display:none;
}  */

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-left: 3%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

.img-fluids {
  max-width: 100%;
  height: 360px;
  margin-left: 15%;
  margin-top: 22%;
  border-radius: 17px;
}

.gstImageStyle {
  height: 455px;
  width: 100%;
}

.topClass {
  margin-top: 0px;
}
.colorWhite {
  color: #fff;
}
.gstH1 {
  margin-top: -2%;
  margin-left: -8%;
}
.gstButton {
  color: #fff;
  cursor: pointer;
}

.invoiceImagecss {
  height: 455px;
  width: 100%;
}
.mr13 {
  margin-left: -13%;
}
.invoiceButtonCss {
  color: #fff;
  cursor: pointer;
}
.investCss {
  height: 455px;
  width: 100%;
}

.tdsSpan {
  color: #fff;
  cursor: pointer;
}

.slide-container .prev:hover,
.slide-container .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

/* Add new css */

/* .importantCss {
  margin: 0%;
}
.marqueeTop {
  margin-top: 0%;
}
.ticker {
  margin-top:0%;
}
.container-xxl {
  margin-top: 5%;
} */
@media (min-width: 768px) and (max-width: 1024px) {
  .Topbox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 0px solid red;
    margin-top: 9%;
  }
  .container-xxl {
    margin-top: 10%;
  }
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: red;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

.modal {
  background-color: #0000008c !important;
  opacity: 1 !important;
}
.project .nav .nav-link.active {
  background: #0f306c;
}

.fact-item:hover .fact-icon {
  background: #0f306c;
}

.owl-carousel-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  /*  background: rgba(0, 0, 0, 0.1); */
}

.img-fluids {
  max-width: 100%;
  height: 360px;
  margin-left: 15%;
  margin-top: 22%;
}

.imageClass {
  height: 455px;
  width: 100%;
}

.carouselFont {
  margin-left: -9%;
}

@media screen and (max-width: 768px) {
  .imageClass {
    height: 455px;
    width: 100%;
  }
  .carousel-item {
    height: 80vh;
  }
  .d-block {
    height: 270px;
  }
  .tdsImage {
    width: 75%;
    margin-top: -1%;
    margin-left: -10%;
  }

  .carouselFont {
    margin-left: 0%;
  }

  .tdsButton {
    margin-left: -7%;
  }

  .hero h1 {
    margin: 0;
    font-size: 45px;
    font-weight: 700;
    color: #012970;
  }

  .tdsmargin {
    padding-left: 0%;
  }

  .invoiceMargin {
    padding-left: 8%;
  }

  .gstReconMargin {
    padding-left: 4%;
  }
  .title:after {
    position: absolute;
    content: "";
    right: -50%;
    /* border-left: 20px solid #5cb85c;
    border-top: 28px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 21px solid transparent; */
    top: 8px;
  }
  .fact-item {
    margin-top: 20% !important;
  }
  .reasonColor {
    margin-top: 20%;
  }
  .features-img img:last-child {
    height: 500px;
  }
  .fadeInUp {
    margin-top: 20% !important;
  }
  .forThe {
    margin-top: 10%;
  }
  .carousel {
    position: relative;
  }
  .carouselText {
    position: absolute;
    left: 50%;
    bottom: 50%;
  }
  .importantCss {
    margin-top: 8% !important;
  }
  /* .ticker {
    margin: -4% !important;
  } */
  .news {
    margin-top: 0%;
  }
  /* .fadeInUp {
    margin-top: 10% !important;
  } */
  .marqueeTop {
    margin-bottom: 10%;
  }
  .reasonColor {
    margin-top: 1% !important;
  }
  /* .nav-pills {
    margin-bottom: 4% !important;
  } */
  .news {
    width: 80%;
  }
}
.mr2 {
  margin-top: -2%;
}
.smallText {
  color: #5cb85c;
}
.height55 {
  height: 55px;
  width: 258px;
}
.maxWidth {
  max-width: 600px;
}
.border-17 {
  border-radius: 17px;
}

.styleClass {
  width: 65px;
  height: 65px;
}

.black {
  color: black;
}
.widthCss {
  width: 65px;
  height: 65px;
}

.fact-item .fact-icon {
  width: 120px;
  height: 120px;
  margin-top: -60px;
  margin-bottom: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 120px;
  transition: 0.5s;
}

.legalColor {
  color: #0e306d;
}
.contactUs {
  color: #5cb85c;
}
.borderRad {
  border-radius: 62px;
}
.reasonColor {
  color: #5cb85c;
}
.licenceColor {
  color: #0e306d;
}
.subColor {
  color: #0e306d;
}

.dateCss {
  color: #fff;
  margin-top: 3%;
}
@media (max-width: 991px) {
  .feature-img .forThe {
    margin-top: 10% !important;
  }
  .feature-img {
    width: 70% !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .smallText {
    padding-top: 2%;
  }
  #padd {
    width: 220px;
  }
  .ticker {
    margin-bottom: 3%;
  }
}

@media (max-width: 768px) {
  .feature-img .forThe {
    margin-top: 10% !important;
  }
  .carousel {
    height: 60vh !important;
    padding: 0px 10px;
  }
  .fact-item {
    margin-top: 6% !important;
    margin-bottom: 4% !important;
  }
  .tdsFonts {
    margin-top: 10% !important;
  }
  .wow {
    margin-top: 0% !important;
  }
  .feature-img {
    margin-top: 3%;
  }
  .contactUs {
    margin-top: 3%;
  }
}

@media (max-width: 480px) {
  .container-xxl h1 {
    font-size: 20px !important;
  }
  .reasonColor {
    margin-top: 1% !important;
  }
  .feature-img .forThe {
    margin-top: 12% !important;
  }
  .subColor {
    font-size: 25px !important;
  }
  .legalColor {
    font-size: 25px !important;
  }
  .tdsFonts {
    font-size: 2.5rem;
  }
  .ticker {
    margin: 0% !important;
  }
  .news {
    width: 80%;
  }
}

@media only screen and (max-width: 1024px) {
  .carousel {
    height: 35vh;
  }
  .carousel-item {
    margin-top: 2%;
  }

  .container-xxl {
    margin-top: 0% !important;
    padding-top: 0% !important;
    padding-bottom: 1% !important;
  }
  .project {
    padding-bottom: 0% !important;
  }
  #padd {
    width: 220px;
  }
  .smallText {
    font-size: 30px;
  }
  .tab .legalColor {
    padding-bottom: 4%;
  }
}
@media only screen and (max-width: 360px) and (max-height: 640px) {
  /* Your styles here */

  .carouselText {
    position: absolute;
    left: 50%;
    bottom: 30%;
  }

  .carousel {
    height: 79vh !important;
    padding: 0px 10px;
  }
}



@media only screen and (max-width: 360px) and (max-height: 760px) {
  /* Your styles here */

  .carouselText {
    position: absolute;
    left: 50%;
    bottom: 30%;
  }

  .carousel {
    height: 79vh !important;
    padding: 0px 10px;
  }
}

@media only screen and (max-width: 375px) and (max-height: 812px) {
  /* Your styles here */

  .carouselText {
    position: absolute;
    left: 50%;
    bottom: 40%;
  }

  .carousel {
    height: 69vh !important;
    padding: 0px 10px;
  }
}

@media only screen and (max-width: 412px) and (max-height: 883px) {
  /* Your styles here */

  .carouselText {
    position: absolute;
    left: 50%;
    bottom: 40%;
  }

  .carousel {
    height: 69vh !important;
    padding: 0px 10px;
  }
}


@media only screen and (max-width: 360px) and (max-height: 640px) {
  /* Your styles here */

  .carouselText {
    position: absolute;
    left: 50%;
    bottom: 30%;
  }

  .carousel {
    height: 69vh !important;
    padding: 0px 10px;
  }
}