/* 
.hero {
	height: 100%;
}
.display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
}
.me-4 {
  margin-right: 0rem !important;
}

.ReconImageCss{
    max-width: 100%;
    margin-top:38%;
}

.keyTop {
	margin-top: 0%;
}

.Topbox {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	border: 0px solid red;
}

.topcontent {
	width: 50%;
	border: 0px solid red;
}

.topimg {
	width: 50%;
	border: 0px solid red;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

@media screen and (max-width:991px) {
	.Topbox {
		flex-wrap: wrap;
		flex-direction: column-reverse;
	}
	.topcontent {
		width: 100%;
		border: 0px solid red;
		margin-bottom: 20px;
	}
	.topcontent h1, h2 {
		text-align: center;
	}
	.topimg {
		width: 100%;
		border: 0px solid red;
		display: flex;
		align-items: center;
		justify-content: space-around;
	}
}

@media ( max-width : 768px) {
	.containerTop {
		margin-top: 0%;
	}
	.asTop {
		margin-top: 0%;
	}
	.keyTop {
		margin-top: 0%;
	}
}

.modal {
	background-color: #0000008c !important;
	opacity: 1!important;
}

.reconTop{
  margin-top:-6%;
}

@media screen and (max-width: 768px) {
  .reconTop{
  margin-top:-26%;
}
  
} */

.hero {
  height: 100%;
}
.display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
}
.me-4 {
  margin-right: 0rem !important;
}

.ReconImageCss {
  max-width: 100%;
  margin-top: 38%;
}

.keyTop {
  margin-top: 0%;
}

/* @media ( max-width : 991px) {
	.hero {
		height: auto;
		padding: 0px 0 0px 0;
	}
}

@media screen and (max-width:1350px) {
	section {
		padding: 0px 0;
	}
} */
.Topbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0px solid red;
}

.topcontent {
  width: 50%;
  border: 0px solid red;
}

.topimg {
  width: 50%;
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media screen and (max-width: 991px) {
  .Topbox {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .topcontent {
    width: 100%;
    border: 0px solid red;
    margin-bottom: 20px;
  }
  .topcontent h1,
  h2 {
    text-align: center;
  }
  .topimg {
    width: 100%;
    border: 0px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

@media (max-width: 768px) {
  .containerTop {
    margin-top: 0%;
  }
  .asTop {
    margin-top: 0%;
  }
  .keyTop {
    margin-top: 0%;
  }
}

.modal {
  background-color: #0000008c !important;
  opacity: 1 !important;
}

.reconTop {
  margin-top: -6%;
}

@media screen and (max-width: 768px) {
  .reconTop {
    margin-top: -26%;
  }
}

@media screen and (max-width: 768px) {
  .ReconImageCss {
    height: 250px;
  }
  .whyRecon {
    margin-top: 0% !important;
  }
}
