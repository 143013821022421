/* .hero {
  height: 100%;
}
.modal-open[style] {
  padding-right: 0px !important;
}

.hero {
  min-height: 100%;
}

.Contact-us {
  background-color: #5cb85c;
}
.features {
  padding-top: 28px;
}
.display-1 {
  font-size: 3.5rem;
}
.features-item {
  width: 100%;
  border-radius: 17px;
}
.features img {
  width: 100%;
  border-radius: 17px;
}
.topcontent p {
  font-size: 19px;
  color: #5cb85c;
}
.topcontent h1 {
  color: #132f6b;
}
.topcontent span {
  color: #fff;
  cursor: pointer;
}
.topcontent i {
  color: white;
}
.topimg img {
  width: 70%;
  border-radius: 17px;
}

.modal-body img {
  width: 50%;
}

.modal-body p i {
  font-size: 50px;
  font-style: tahoma;
  text-align: center;
  width: 100%;
}
.modal-body img {
  width: 100%;
  height: 169px;
}
label {
  font-size: 18px;
}
.spanRed {
  color: red;
}
#successMsg {
  z-index: 9999;
  padding-top: 59px;
}
.modal-dialog {
  z-index: 9999;
}
.modal-content {
  width: auto;
  align-items: center;
  background-color: #f5f5f5;
  color: #0f316d;
  z-index: 9999;
}
.modal-header i {
  font-size: 50px;
  font-style: tahoma;
}
.msg {
  font-size: 20px;
  margin-left: 25px;
}
.modal-header h4 {
  font-size: 18px;
  font-weight: bold;
  width: 100%;
}
.digSignim {
  margin-top: 25%;
  border-radius: 17px;
}
.modal-body img {
  height: 120px;
}
.order-first img {
  margin-top: 15%;
}
.services {
  margin-top: 0%;
}

@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 0px 0 0px 0;
  }
}
.features {
  padding-top: 15px;
}

.Topbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0px solid red;
}

.topcontent {
  width: 50%;
  border: 0px solid red;
}

.topimg {
  width: 50%;
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media screen and (max-width: 991px) {
  .Topbox {
    flex-wrap: wrap;
  }
  .topcontent {
    width: 100%;
    border: 0px solid red;
  }
  .topcontent h1,
  h2 {
    text-align: center;
  }
  .topimg {
    width: 100%;
    border: 0px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.about p {
  margin: 0px 0 0px 0;
  line-height: 25px;
}

@media (max-width: 768px) {
  .hideImage {
    display: none;
  }
  
  .documentsReq {
    margin-top: 0%;
  }
  .advantageTop {
    margin-top: 0%;
  }
  .stepTop {
    margin-top: 0%;
  }
}

.modal {
  background-color: #0000008c !important;
  opacity: 1 !important;
} */

.hero {
  height: 100%;
}

/* #hero {
	width: 100%;
	position: relative;
	background: url("static/assetse/img/hero-bg.png") center bottom
		no-repeat;
	background-size: auto;
	background-size: cover;
	padding: 120px 0 120px 0;
} */

.modal-open[style] {
  padding-right: 0px !important;
}

.hero {
  min-height: 100%;
}
/* 
.Contact-us {
  background-color: #5cb85c;
} */
.features {
  padding-top: 28px;
}
.display-1 {
  font-size: 2.5rem;
}
.features-item {
  width: 100%;
  border-radius: 17px;
}
.features img {
  width: 100%;
  border-radius: 17px;
}
.topcontent p {
  font-size: 19px;
  color: #5cb85c;
}
.topcontent h1 {
  color: #132f6b;
}
.topcontent span {
  color: #fff;
  cursor: pointer;
}
.topcontent i {
  color: white;
}
.topimg img {
  width: 80%;
  /* margin-top: -1%; */
  border-radius: 17px;
}

.modal-body img {
  width: 50%;
}

.modal-body p i {
  font-size: 50px;
  font-style: tahoma;
  text-align: center;
  width: 100%;
}
.modal-body img {
  width: 100%;
  height: 169px;
}
label {
  font-size: 18px;
}
.spanRed {
  color: red;
}
#successMsg {
  z-index: 9999;
  padding-top: 59px;
}
.modal-dialog {
  z-index: 9999;
}
.modal-content {
  width: auto;
  align-items: center;
  background-color: #f5f5f5;
  color: #0f316d;
  z-index: 9999;
}
.modal-header i {
  font-size: 50px;
  font-style: tahoma;
}
.msg {
  font-size: 20px;
  margin-left: 25px;
}
.modal-header h4 {
  font-size: 18px;
  font-weight: bold;
  width: 100%;
}
/* .digSignim {
  margin-top: 25%;
  border-radius: 17px;
} */
.modal-body img {
  height: 120px;
}
/* .order-first img {
   margin-top: 20%; 
} */
.services {
  margin-top: 0%;
}
.content h3 {
  font-weight: 600 !important;
  /* padding-left: 0%; */
  margin-left: 0%;
}
.section-header h2 {
  padding-top: 0%;
  padding-bottom: 1%;
  margin-top: 0%;
}
.stepTop {
  padding-bottom: 0% !important;
}
@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 0px 0 0px 0;
  }
  .topimg {
    margin-top: 2%;
  }
  .order-first img {
    margin-top: 0%;
  }
  .topcontent p {
    text-align: center;
  }
  .stepTop {
    padding-top: 0% !important;
  }
}

/* @media screen and (max-width:1350px) {
	section {
		padding: 0px 0;
	}
} */

/* .advantageTop{
  margin-top:-6%;
} */
.features {
  padding-top: 15px;
}

.Topbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0px solid red;
}

.topcontent {
  width: 50%;
  border: 0px solid red;
}

.topimg {
  width: 50%;
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media screen and (max-width: 991px) {
  .Topbox {
    flex-wrap: wrap;
  }
  .topcontent {
    width: 100%;
    border: 0px solid red;
  }
  .topcontent h1,
  h2 {
    text-align: center;
    padding-top: 12px;
  }
  .topimg {
    width: 100%;
    border: 0px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  /* .order-first img {
    margin-top: 0%;
  } */
}

.about p {
  margin: 0px 0 0px 0;
  line-height: 25px;
}

@media (max-width: 768px) {
  .hideImage {
    display: none;
  }
  /* #hero {
		width: 100%;
		position: relative;
		background: url("static/assetse/img/hero-bg.png") center bottom
			no-repeat;
		background-size: auto;
		background-size: auto;
		background-size: cover;
		padding: 90px 0 0px 0;
	} */
  .documentsReq {
    margin-top: 0%;
  }
  .advantageTop {
    margin-top: 0%;
  }
  .stepTop {
    margin-top: 0%;
    margin-bottom: 3%;
  }
  .order-first img {
    margin-top: 0%;
  }
  .about {
    margin-top: 1% !important;
  }
  .advantageTop {
    padding-bottom: 0%;
  }
  .whyRecon {
    margin-top: 0% !important;
  }
}

@media (max-width: 480px) {
  .section-header h2 {
    padding: 15px 18px;
  }
  .whyRecon {
    margin-top: 0% !important;
  }
  .imgalign{
    margin-top: -25%;
  }
}

.modal {
  background-color: #0000008c !important;
  opacity: 1 !important;
}

.fontTextalign{
display: flex !important;
align-items: center !important;
margin-top: 0% !important;
}
