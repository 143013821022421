/* .hero {
  height: 100%;
}
.page-header a {
  background-color: #5cb85c;
}
.page-header h1 {
  font-size: 3.5em;
}
.page-header img {
  width: 80%;
  height: 100%;
  margin-left: 10%;
  margin-top: -20px;
}
.about img {
  margin-top: 15%;
  border-radius: 17px;
}
.features {
  margin-top: -8%;
}
.faq-title h1 {
  color: #012970;
}
.me-4 {
  margin-right: 0rem !important;
}

.topcontent p {
  font-size: 19px;
  color: #5cb85c;
}
.topcontent h1 {
  color: #132f6b;
}
.topcontent span {
  color: #fff;
  cursor: pointer;
}
.topcontent i {
  color: white;
}
.topimg img {
  width: 80%;
  margin-top: -1%;
}
.section-header p {
  font-size: 15px;
  margin-top: 0;
}
.section-header h2 {
  margin-top: 3%;
  color: #001f8d;
}
.faq-title h2 {
  color: #001f8d;
}
.modal-body p i {
  font-size: 50px;
  font-style: tahoma;
  text-align: center;
  width: 100%;
}
.modal-body img {
  width: 100%;
  height: 169px;
}
label {
  font-size: 14px;
}
.spanRed {
  color: red;
}
#successMsg {
  z-index: 9999;
  padding-top: 59px;
}
.modal-dialog {
  z-index: 9999;
}
.modal-content {
  width: auto;
  align-items: center;
  background-color: #f5f5f5;
  color: #0f316d;
  z-index: 9999;
}
.modal-header i {
  font-size: 50px;
  font-style: tahoma;
}
.msg {
  font-size: 20px;
  margin-left: 25px;
}
.modal-header h4 {
  font-size: 18px;
  font-weight: bold;
  width: 100%;
}
.digSignim {
  margin-top: 25%;
  border-radius: 17px;
}
.modal-body img {
  height: 120px;
}

.bookImage {
  width: -moz-available;
  border-radius: 3%;
  width: 100%;
  margin-top: -9%;
  background-size: auto, auto;
  background-size: cover;
}

@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 0px 0 0px 0;
  }
}

.Topbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0px solid red;
}

.topcontent {
  width: 50%;
  border: 0px solid red;
}

.topimg {
  width: 50%;
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media screen and (max-width: 991px) {
  .Topbox {
    flex-wrap: wrap;
  }
  .topcontent {
    width: 100%;
    border: 0px solid red;
  }
  .topcontent h1,
  h2 {
    text-align: center;
  }
  .topimg {
    width: 100%;
    border: 0px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

@media (min-width: 1365px) {
  .call-to-action {
    background-attachment: fixed;
  }
}
.modal {
  background-color: #0000008c !important;
  opacity: 1 !important;
}

@media (max-width: 768px) {
  .bookImage {
    width: -moz-available;
    border-radius: 3%;
    width: 100%;
    margin-top: 6%;
    background-size: auto, auto;
    background-size: cover;
  }
} */
.hero {
  height: 100%;
}

/* .page-header {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(static/assets/img/minslider/investmentSlider.jpg) center center
      no-repeat;
  background-size: auto, auto;
  background-size: cover;
} */

.page-header a {
  background-color: #5cb85c;
}
.page-header h1 {
  font-size: 3.5em;
}
.page-header img {
  width: 80%;
  height: 100%;
  margin-left: 10%;
  margin-top: -20px;
  mix-blend-mode: hard-light;
}
.about img {
  margin-top: 10%;
  border-radius: 17px;
}
.features {
  margin-top: -8%;
}
.faq-title h1 {
  color: #012970;
}
.me-4 {
  margin-right: 0rem !important;
}

.topcontent p {
  font-size: 19px;
  color: #5cb85c;
}
.topcontent h1 {
  color: #132f6b;
}
.topcontent span {
  color: #fff;
  cursor: pointer;
}
.topcontent i {
  color: white;
}
.topimg img {
  width: 80%;
  margin-top: -1%;
}
.section-header p {
  font-size: 15px;
  margin-top: 0;
}
.section-header h2 {
  margin-top: 3%;
  color: #001f8d;
}
.faq-title h2 {
  color: #001f8d;
}
.modal-body p i {
  font-size: 50px;
  font-style: tahoma;
  text-align: center;
  width: 100%;
}
.modal-body img {
  width: 100%;
  height: 169px;
}
label {
  font-size: 14px;
}
.spanRed {
  color: red;
}
#successMsg {
  z-index: 9999;
  padding-top: 59px;
}
.modal-dialog {
  z-index: 9999;
}
.modal-content {
  width: auto;
  align-items: center;
  background-color: #f5f5f5;
  color: #0f316d;
  z-index: 9999;
}
.modal-header i {
  font-size: 50px;
  font-style: tahoma;
}
.msg {
  font-size: 20px;
  margin-left: 25px;
}
.modal-header h4 {
  font-size: 18px;
  font-weight: bold;
  width: 100%;
}
.digSignim {
  margin-top: 25%;
  border-radius: 17px;
}
.modal-body img {
  height: 120px;
}

.bookImage {
  width: -moz-available;
  border-radius: 3%;
  width: 100%;
  margin-top: -9%;
  background-size: auto, auto;
  background-size: cover;
}

@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 0px 0 0px 0;
  }
}

.Topbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0px solid red;
}

.topcontent {
  width: 50%;
  border: 0px solid red;
}

.topimg {
  width: 50%;
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media screen and (max-width: 991px) {
  .Topbox {
    flex-wrap: wrap;
  }
  .topcontent {
    width: 100%;
    border: 0px solid red;
  }
  .topcontent h1,
  h2 {
    text-align: center;
  }
  .topimg {
    width: 100%;
    border: 0px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

@media (min-width: 1365px) {
  .call-to-action {
    background-attachment: fixed;
  }
}

/* .call-to-action {
  background: linear-gradient(rgba(14, 29, 52, 0.6), rgba(14, 29, 52, 0.8)),
    url("static/assetse/img/bookkeepingBack.jpg") center center;
  background-attachment: scroll, scroll;
  background-size: auto, auto;
  background-size: cover;
  padding: 120px 0 120px 0;
} */

.modal {
  background-color: #0000008c !important;
  opacity: 1 !important;
}

@media (max-width: 1024px) {
  #hero {
    padding-top: 12%;
  }
}

@media (max-width: 768px) {
  .bookImage {
    width: -moz-available;
    border-radius: 3%;
    width: 100%;
    margin-top: 6%;
    background-size: auto, auto;
    background-size: cover;
  }
  .hero {
    padding-top: 24% !important;
  }
  .whyRecon {
    margin-top: 0% !important;
  }
}
@media (max-width: 480px) {
  #hero {
    margin-top: -20%;
  }
  .topcontent .resp {
    margin-top: -10%;
  }
  .whyRecon {
    margin-top: 0% !important;
  }
}
