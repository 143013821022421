/* 
.hero {
	height: 100%;
}

.features .feature-box h3 {
  font-size: 22px;
  color: #012970;
  font-weight: 700;
  margin: 0;
}
.features .feture-tabs .tab-content h4 {
  font-size: 22px;
  margin: 0;
  font-weight: 700;
  color: #012970;
}
.display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
}
.investmentProof{
	font-size:3em;color:#0f316d;
}

.how{
	font-size:2em;color:#0f316d;
	margin-top:5%;
}

.investmentTop{
	margin-top: -4%;
}

.benefitsTop{
	margin-top:-24%
}

.worksCss{
	margin-top: -5%;
}

.keyFeaCss{
	font-size:2em;color:#0f316d
}

.investmentFontSize{
	font-size:2em;color:#0f316d
}

.investTops{
	margin-top:-10%
}

.contactUsCss{
	background-color:#5cb85c;
}
.investmentProofImg{
	width: 100%;
	margin-top: 38%;
}

.investmentProofImageCss{
	width:100%;border-radius:3%;
    background-size: auto, auto;
  background-size: cover
}

   .page-header {
 
    background-size: auto, auto;
  background-size: cover;
}
 

@media ( max-width : 991px) {
	.hero {
		height: auto;
		padding: 0px 0 0px 0;
	}
}

.Topbox {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	border: 0px solid red;
}

.topcontent {
	width: 50%;
	border: 0px solid red;
}

.topimg {
	width: 50%;
	border: 0px solid red;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

@media screen and (max-width:991px) {
	.Topbox {
		flex-wrap: wrap;
		flex-direction: column-reverse;
	}
	.topcontent {
		width: 100%;
		border: 0px solid red;
		margin-bottom: 20px;
	}
	.topcontent h1, h2 {
		text-align: center;
	}
	.topimg {
		width: 100%;
		border: 0px solid red;
		display: flex;
		align-items: center;
		justify-content: space-around;
		margin-top: 0%
	}
}

.about p {
	margin: -21px 0 30px 0;
	line-height: 40px;
}

.keyTop {
	margin-top: 0%;
}

@media ( max-width : 768px) {
	.features .feture-tabs {
		margin-top: 31px;
	}
	.worksTop {
		margin-top: 0%;
	}
	.keyTop {
		margin-top: 0%;
	}
}

.modal {
	background-color: #0000008c !important;
	opacity: 1 !important;
} */

.hero {
  height: 100%;
}

.features .feature-box h3 {
  font-size: 22px;
  color: #012970;
  font-weight: 700;
  margin: 0;
}
.features .feture-tabs .tab-content h4 {
  font-size: 22px;
  margin: 0;
  font-weight: 700;
  color: #012970;
}
.display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
}
.investmentProof {
  font-size: 3em;
  color: #0f316d;
}

.how {
  font-size: 2em;
  color: #0f316d;
  margin-top: 5%;
}

.investmentTop {
  margin-top: -4%;
}

.benefitsTop {
  margin-top: -24%;
}

.worksCss {
  margin-top: -5%;
}

.keyFeaCss {
  font-size: 2em;
  color: #0f316d;
}

.investmentFontSize {
  font-size: 2em;
  color: #0f316d;
}

.investTops {
  margin-top: -10%;
}

.contactUsCss {
  background-color: #5cb85c;
}
.investmentProofImg {
  width: 100%;
  margin-top: 38%;
}

.investmentProofImageCss {
  width: 100%;
  border-radius: 3%;
  background-size: auto, auto;
  background-size: cover;
}

.page-header {
  background-size: auto, auto;
  background-size: cover;
}
.keyFeaCss,
.how {
  padding-left: 25px;
}

@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 0px 0 0px 0;
  }
}

/* @media screen and (max-width:1350px) {
	  section {
		  padding: 0px 0;
	  }
  } */
.Topbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0px solid red;
}

.topcontent {
  width: 50%;
  border: 0px solid red;
}

.topimg {
  width: 50%;
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media screen and (max-width: 991px) {
  .Topbox {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .topcontent {
    width: 100%;
    border: 0px solid red;
    margin-bottom: 20px;
  }
  .topcontent h1,
  h2 {
    text-align: center;
  }
  .topimg {
    width: 100%;
    border: 0px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 0%;
  }
}

.about p {
  margin: -21px 0 30px 0;
  line-height: 40px;
}

.keyTop {
  margin-top: 0%;
}

@media (max-width: 768px) {
  .features .feture-tabs {
    margin-top: 31px;
  }
  .worksTop {
    margin-top: 0%;
  }
  .keyTop {
    margin-top: 0%;
  }
  .whyRecon {
    margin-top: 0% !important;
  }
}

@media (max-width: 480px) {
  .keyFeaCss,
  .how {
    padding-left: 40px;
  }
  .infoColor {
    padding-left: 8xpx;
    padding-right: 8px;
  }
  .keyFeaCss {
    margin-top: 4%;
  }
  .whyRecon {
    margin-top: 0% !important;
  }
}

.modal {
  background-color: #0000008c !important;
  opacity: 1 !important;
}
