@media (max-width: 991px) {
  .rocImg {
    height: 300px;
  }
  #about {
    margin-top: 2%;
  }
}
@media (max-width: 768px) {
  .Topbox {
    padding-top: 6% !important;
  }
  .rocImg {
    padding-top: 3% !important;
  }
}
@media (max-width: 480px) {
  .Topbox {
    padding-top: 8% !important;
  }
}
