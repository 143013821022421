/* 
.hero {
	height: 100%;
	padding-bottom: 0%
}
.display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
}
.me-4 {
  margin-right: 0rem !important;
}

.featuresTopOb {
	margin-top: 0%;
}

.featuresTop {
	margin-top: 0%;
}

.decTop {
	margin-top: 0%;
}
.Topbox {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	border: 0px solid red;
	margin-top: 0%;
}

.topcontent {
	width: 50%;
	border: 0px solid red;
}

.topimg {
	width: 50%;
	border: 0px solid red;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

@media screen and (max-width:991px) {
	.Topbox {
		flex-wrap: wrap;
		flex-direction: column-reverse;
	}
	.topcontent {
		width: 100%;
		border: 0px solid red;
		margin-bottom: 20px;
	}
	.topcontent h1, h2 {
		text-align: center;
	}
	.topimg {
		width: 100%;
		border: 0px solid red;
		display: flex;
		align-items: center;
		justify-content: space-around;
	}
}

section {
	padding: 0px 0px 0px;
}

.invoiceCssClass{
    width: 90%; margin-top: -10%;
}
.infoSmart{
    color: #0e306d;
}

.modal {
	background-color: #0000008c !important;
}

@media screen and (max-width: 768px) {
   p{
     margin-left:4%;margin-right:4%;
   }
   } */

.hero {
  height: 100%;
  padding-bottom: 0%;
}
.display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
}
.me-4 {
  margin-right: 0rem !important;
}

.featuresTopOb {
  margin-top: 0%;
}

.featuresTop {
  margin-top: 0%;
}

.decTop {
  margin-top: 0%;
}

/* @media ( max-width : 991px) {
	  .hero {
		  height: auto;
		  padding: 0px 0 0px 0;
	  }
  } */

/* @media screen and (max-width:1350px) {
	  section {
		  padding: 0px 0;
	  }
  }
   */
.Topbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0px solid red;
  margin-top: 0%;
}

.topcontent {
  width: 50%;
  border: 0px solid red;
}

.topimg {
  width: 50%;
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media screen and (max-width: 991px) {
  .Topbox {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .topcontent {
    width: 100%;
    border: 0px solid red;
    margin-bottom: 20px;
  }
  .topcontent h1,
  h2 {
    text-align: center;
  }
  .topimg {
    width: 100%;
    border: 0px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .table {
    width: 100%;
    /* margin-left: 50px; */
  }
  .table tr {
    text-align: center;
  }
  .table td {
    padding-top: 5px;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 10px;
  }
}

section {
  padding: 0px 0px 0px;
}

.invoiceCssClass {
  width: 90%;
  margin-top: -10%;
}
.infoSmart {
  color: #0e306d;
}

.modal {
  background-color: #0000008c !important;
}

@media screen and (max-width: 768px) {
  p {
    margin-left: 4%;
    margin-right: 4%;
  }
  /* .table {
    width: 100%;
  }
  .table tr {
    text-align: center;
  }
  .table td {
    padding-top: 5px;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 10px;
  } */
  .table-responsive {
    width: 100% !important;
  }
  /* .table-responsive td:nth-child(odd) {
    text-align: right;
  } */
  .table-responsive td:nth-child(even) {
    text-align: left;
  }
  #about {
    margin-top: 0% !important;
  }
  #whyRecon {
    margin-top: 0% !important;
  }
}
@media (max-width: 480px) {
  .table {
    /* padding: 0%; */
    width: 100%;
  }
  .table tr {
    text-align: start;
  }
  .row h3 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .table-responsive td {
    font-size: 14px;
    padding: 10px;
  }
  .tablewidths{
    width:100% !important;
    }
    
}

/* Apply border-radius to the table */
/* p{
  color: black !important;
} */


.tablewidths{
width: 1086px ;
}
