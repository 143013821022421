/* 
.taxosmartTop {
	margin-top: 0%;
}

.bulkFormStyle{
    width: 61%; margin-top: 0%;
}

.auto15CACB{
    color:#0f316d;
}



.display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
}

.me-4 {
  margin-right: 0rem !important;
}

.topCssDiv{
    margin-top:0%
}

.mtop{
    margin-top: 3%;
}

.taxosmartCss{
    font-size:2em;color:#0f316d;text-align: center;
}

.introDuction{
    font-size:2em;color:#0f316d;text-align: center;
}

.personTop {
	margin-top: 8%;
}
.topcontentS{
    width: 50%;
  border: 0px solid red;
  margin-top: -3%;
}

.IntroductionTop {
	margin-top: 0%;
}

.hero {
	height: 100%;
}

@media ( max-width : 768px) {
	.about p {
		margin: -30px 0 30px 0;
		line-height: 24px;
	}
	.personTop {
		margin-top: 0%;
	}
	.newRule {
		margin-top: 0%;
	}
	.taxosmartTop {
		margin-top: 0%;
	}
	.featuresTop {
		margin-top: 0%;
	}
}

@media ( max-width : 991px) {
	.hero {
		height: auto;
		padding: 0px 0 0px 0;
	}
}


.Topbox {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	border: 0px solid red;
}

.topcontent {
	width: 50%;
	border: 0px solid red;
}

.topimg {
	width: 50%;
	border: 0px solid red;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

@media screen and (max-width:991px) {
	.Topbox {
		flex-wrap: wrap;
		flex-direction: column-reverse;
	}
	.topcontent {
		width: 100%;
		border: 0px solid red;
		margin-bottom: 20px;
	}
	.topcontent h1, h2 {
		text-align: center;
	}
	.topimg {
		width: 100%;
		border: 0px solid red;
		display: flex;
		align-items: center;
		justify-content: space-around;
	}
}

@media ( max-width : 768px) {
	.decTop {
		margin-top: 0%;
	}
}

.modal {
	background-color: #0000008c !important;
}

.modal {
	background-color: #0000008c !important;
}

.topClass{
 padding: 0px 0px;margin-top:-6%;
}

@media screen and (max-width: 768px) {
   p{
     margin-left:4%;margin-right:4%;
   }
   
   .topClass{
 padding: 0px 0px;margin-top:0%;
}
}

@media screen and (max-width: 991px) {
  .topcontent {
    width: 89%;
    border: 0px solid red;
    margin-bottom: 20px;
    margin-top: 4%;
  }
} */

.heroTop {
  padding-top: 14% !important;
}
.Topbox {
  padding-top: 8%;
}
.taxosmartTop {
  margin-top: 0%;
}

.bulkFormStyle {
  width: 61%;
  margin-top: 0%;
}

.auto15CACB {
  color: #0f316d;
}

.display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
}

.bulkFormStyle {
  height: 280px;
}

.me-4 {
  margin-right: 0rem !important;
}

.topCssDiv {
  margin-top: 0%;
}

.mtop {
  margin-top: 3%;
}

.taxosmartCss {
  font-size: 2em;
  color: #0f316d;
  text-align: center;
}

.introDuction {
  font-size: 2em;
  color: #0f316d;
  text-align: center;
}

.personTop {
  margin-top: 8%;
}
.topcontentS {
  width: 50%;
  border: 0px solid red;
  margin-top: -3%;
}

.IntroductionTop {
  margin-top: 0%;
}

.hero {
  height: 100%;
}

/* section {
	  padding: 45px 0px;
  } */
@media (max-width: 768px) {
  .about p {
    margin: -30px 0 30px 0;
    line-height: 24px;
  }
  .personTop {
    margin-top: 0%;
  }
  .newRule {
    margin-top: 0%;
  }
  .taxosmartTop {
    margin-top: 0%;
  }
  .featuresTop {
    margin-top: 0%;
  }
}

@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 0px 0 0px 0;
  }
}

@media screen and (max-width: 1350px) {
  section {
    padding: 0px 0;
  }
}

.Topbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0px solid red;
}

.topcontent {
  width: 50%;
  border: 0px solid red;
}

.topimg {
  width: 50%;
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media screen and (max-width: 991px) {
  .Topbox {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .topcontent {
    width: 100%;
    border: 0px solid red;
    margin-bottom: 20px;
  }
  .topcontent h1,
  h2 {
    text-align: center;
  }
  .topimg {
    width: 100%;
    border: 0px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .auto15CACB {
    padding-top: 5%;
  }
  .bulkFormStyle {
    width: 60% !important;
  }
}

@media (max-width: 768px) {
  .decTop {
    margin-top: 0%;
  }
}

.modal {
  background-color: #0000008c !important;
}

.modal {
  background-color: #0000008c !important;
}

.topClass {
  padding: 0px 0px;
  margin-top: -6%;
}

@media screen and (max-width: 768px) {
  p {
    margin-left: 4%;
    margin-right: 4%;
    padding-top: 4px;
  }

  .topClass {
    padding: 0px 0px;
    margin-top: 0%;
  }
}

@media screen and (max-width: 991px) {
  .topcontent {
    width: 89%;
    border: 0px solid red;
    margin-bottom: 20px;
    margin-top: 4%;
  }
  .auto15CACB {
    text-align: center;
  }
  .decTop {
    padding-top: 0% !important;
  }
}
@media (max-width: 480px) {
  .auto15CACB {
    padding-top: 5%;
  }
  #padd {
    margin-left: 0px !important;
  }
}
