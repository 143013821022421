/* .modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.tdsMarginTop{
  margin-top: -7%;
}
.tdsContainer{
max-width: 600px; visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;
}
.featureColor{
  color: #5cb85c;
}
.feaColor{
  color: #5cb85c;
}
.offColor{
  color: #132f6b;
}
.fadeEffect{
  visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;
}
.fadeCss{
  max-width: 600px; visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;

}

.textDecoration{
  text-decoration: none;

}

.tdsFile{
  visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;
}
.onlineColor{

  color: #132f6b;
}
.offcanvas-header {
    display: none;
  }
  .tdsColorFont{
    max-width: 600px;

  }
  .serviceColor{
    color: #5cb85c;
  }
  .informationColor{
    color: #132f6b;

  }

  .tdsFont{
    background-color: #5cb85c
    
  }
  
  .offcanvas-header .btn-close {
    padding: .5rem .5rem;
    margin: -.5rem -.5rem -.5rem auto;
      margin-left: auto;
    margin-left: 94%;
  }
  
  .screen-overlay {
    width: 0%;
    height: 100%;
    z-index: 30;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(34, 34, 34, 0.6);
    transition: opacity 0.2s linear, visibility 0.1s, width 1s ease-in;
  }
  .screen-overlay.show {
    transition: opacity 0.5s ease, width 0s;
    opacity: 1;
    width: 100%;
    visibility: visible;
  }
  
  @media all and (max-width: 992px) {
    .offcanvas-header {
      display: block;
    }
  
    .mobile-offcanvas {
      visibility: hidden;
      transform: translateX(-100%);
      border-radius: 0;
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      z-index: 1200;
      width: 80%;
      overflow-y: scroll;
      overflow-x: hidden;
      transition: visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
      background-color: #fff !important;
    }
  
    html:lang(ar) .mobile-offcanvas {
      transform: translateX(100%);
    }
  
    .mobile-offcanvas.show {
      visibility: visible;
      transform: translateX(0);
    }
  
    .nav-cont {
      display: block !important;
      padding-right: auto !important;
      padding-left: auto !important;
    }
  
    .navbar2 {
      box-shadow: 0px 3px 17px #00000047;
    }
    #navbar_main {
      box-shadow: 0px 3px 17px #00000047;
    }
    .dropdown-menu {
      border: none;
    }
    .nav-item {
      border-bottom: 1px solid #eaeaea;
    }
  }
  
  
  
  @media screen and (max-width: 768px) {
  .navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
    margin-top: 0%;
  }
  
  .mobileNavbar{
     visibility: visible;
    animation-delay: 0.1s;
    top: 28px;
  }
  
  }
  
  

  .display-5 {
      font-size: calc(0.3rem + 2.1vw);
      font-weight: 600;
      line-height: 1.2;
      display: block ruby;
  }
  

  .tdsh{
    color: #132f6b;
  }
  .tdsImage {
      width: 70%;
      margin-left: 26%;
      margin-top: 5%;
  }
  
  @media screen and (max-width: 768px) {
      .tdsImage {
          width: 70%;
          margin-left: 13%;
          margin-top: 5%;
      }
  } */

.tdsMarginTop {
  margin-top: -17% !important;
}
.tdsContainer {
  max-width: 600px;
  visibility: visible;
  animation-delay: 0.1s;
  animation-name: fadeInUp;
}
.featureColor {
  color: #5cb85c;
}
.feaColor {
  color: #5cb85c;
}
.offColor {
  color: #132f6b;
}
.fadeEffect {
  visibility: visible;
  animation-delay: 0.1s;
  animation-name: fadeInUp;
}
.fadeCss {
  max-width: 600px;
  visibility: visible;
  animation-delay: 0.1s;
  animation-name: fadeInUp;
}

.textDecoration {
  text-decoration: none;
}

.tdsFile {
  visibility: visible;
  animation-delay: 0.1s;
  animation-name: fadeInUp;
}
.onlineColor {
  color: #132f6b;
}
.offcanvas-header {
  display: none;
}
.tdsColorFont {
  max-width: 600px;
}
.serviceColor {
  color: #5cb85c;
}
.informationColor {
  color: #132f6b;
}

.tdsFont {
  background-color: #5cb85c;
}

.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
  margin-left: auto;
  margin-left: 94%;
}

.screen-overlay {
  width: 0%;
  height: 100%;
  z-index: 30;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(34, 34, 34, 0.6);
  transition: opacity 0.2s linear, visibility 0.1s, width 1s ease-in;
}
.screen-overlay.show {
  transition: opacity 0.5s ease, width 0s;
  opacity: 1;
  width: 100%;
  visibility: visible;
}
/* .fadeCss {
  padding-bottom: 0% !important;
}
.offColor {
  padding-bottom: 0% !important;
} */
@media all and (max-width: 992px) {
  .offcanvas-header {
    display: block;
  }

  .mobile-offcanvas {
    visibility: hidden;
    transform: translateX(-100%);
    border-radius: 0;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1200;
    width: 80%;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
    background-color: #fff !important;
  }

  html:lang(ar) .mobile-offcanvas {
    transform: translateX(100%);
  }

  .mobile-offcanvas.show {
    visibility: visible;
    transform: translateX(0);
  }

  .nav-cont {
    display: block !important;
    padding-right: auto !important;
    padding-left: auto !important;
  }

  .navbar2 {
    box-shadow: 0px 3px 17px #00000047;
  }
  #navbar_main {
    box-shadow: 0px 3px 17px #00000047;
  }
  .dropdown-menu {
    border: none;
  }
  .nav-item {
    border-bottom: 1px solid #eaeaea;
  }
  .featureColor {
    font-size: 40px;
  }
  .feaColor {
    font-size: 40px;
  }
  .serviceColor {
    font-size: 40px;
  }
  .tdsImage {
    margin-left: 15% !important;
  }
}

@media screen and (max-width: 768px) {
  .navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
    margin-top: 0%;
  }

  .mobileNavbar {
    visibility: visible;
    animation-delay: 0.1s;
    top: 28px;
  }
  .featureColor {
    font-size: 40px;
  }
  .feaColor {
    font-size: 40px;
  }
  .serviceColor {
    font-size: 40px;
  }

  .row {
    margin-top: 10px !important;
  }
}
/* 
  .page-header {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(C:\Users\STAFF233\react\taxosmart\src\static\image\img\TDS_slider.jpg)
        center center no-repeat;
    background-size: auto, auto;
    background-size: cover;
  } */

.display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
}

.tdsh {
  color: #132f6b;
}
.tdsImage {
  width: 70%;
  margin-left: 26%;
  margin-top: 5%;
}

@media screen and (max-width: 768px) {
  .tdsImage {
    width: 70%;
    margin-left: 13%;
    margin-top: 5%;
  }
}
@media (max-width: 480px) {
  .serviceColor {
    font-size: 30px;
  }
  /* .container {
      padding: 0%;
      margin-top: 0%;
    } */
  .tdsImage {
    margin-top: 0%;
  }
  .featureColor {
    font-size: 20px !important;
  }
  .feaColor {
    font-size: 30px;
  }
  .display-5 {
    padding-bottom: 0%;
  }
  .onlineColor {
    font-size: 30px;
  }
  .offColor {
    font-size: 25px;
  }
  h4 {
    font-size: 16px !important;
  }
  .display-1{
    font-size: 2.5rem !important;
  }
  .page-header a {
    border-radius: 7px !important;
    width:160px !important;
  }
  .about h2 {
    font-size: 27px !important;
    margin-top: 0% !important;
    
  }
  .wow {
    margin-bottom: 0% !important;
  }
  .mobCol{
    flex-direction: column !important;
  }
  .mobwid{
    width: 260px !important;
  }
 
}

.form-control{
  border-radius: 6px !important;
}


.about h2{
  padding: 0 !important;
}







@media only screen and (max-width: 1024px) {
  .fadeIn {
    margin-bottom: 1% !important;
    padding-bottom: 1% !important;
    margin-bottom: 0%;
  }
  .container-xxl {
    padding-bottom: 0% !important;
  }
  .about {
    padding-top: 0% !important;
  }
  .container-xxl {
    padding: 0%;
  }
}


 .btn-get-started {
  margin-top: 30px;
  line-height: 0;
  padding: 15px 40px;
  border-radius: 10px;
  transition: 0.5s;
  color: #fff;
  background: #4154f1 !important;
  box-shadow: 0px 5px 30px rgba(65, 84, 241, 0.4);
}