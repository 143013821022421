/* 
  
  
  .hero {
      height: 100%;
  }
  .display-5 {
    font-size: calc(0.3rem + 2.1vw);
    font-weight: 600;
    line-height: 1.2;
    display: block ruby;
  }
  .me-4 {
    margin-right: 0rem !important;
  }
  
  .decTop {
      margin-top: -3%;
  }
 
  .Topbox {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border: 0px solid red;
      margin-top: -8%;
  }
  
  .topcontents {
      width: 50%;
      border: 0px solid red;
  }
  
  .topimg {
      width: 50%;
      border: 0px solid red;
      display: flex;
      align-items: center;
      justify-content: space-around;
  }
  
  @media screen and (max-width:991px) {
      .Topbox {
          flex-wrap: wrap;
          flex-direction: column-reverse;
      }
      .topcontents {
          width: 100%;
          border: 0px solid red;
          margin-bottom: 20px;
          margin-top:11%;
      }
      .topcontents h1, h2 {
          text-align: center;
      }
      .topimg {
          width: 100%;
          border: 0px solid red;
          display: flex;
          align-items: center;
          justify-content: space-around;
      }
  }
  
  @media ( max-width : 768px) {
      .Topbox {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          border: 0px solid red;
          margin-top: -23%;
      }
      .itrImage {
          margin-top: 0%;
      }
      .keyTop {
          margin-top: 0%;
      }
  }
  
  .modal {
      background-color: #0000008c !important;
      opacity: 1 !important; 
  }
  
  .display-5 {
    font-size: calc(0.3rem + 2.1vw);
    font-weight: 600;
    line-height: 1.2;
    display: block ruby;
  }
  
.pdfImage{
    width: 100%; ;margin-top: 5%;
  }

  .gstFont{
    color:#132f6b;font-size: calc(0.3rem + 2.1vw);
    font-weight: 600;
    line-height: 1.2;
  }

  .pdfPadding{
    padding: 50px 0px;
  }
  
  @media screen and (max-width: 768px) {
   
  .gstFont{
    color:#132f6b;font-size: calc(1rem + 2.1vw);
    font-weight: 600;
    line-height: 1.2;
  }
  } */

.hero {
  height: 100%;
}
.display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
}
.me-4 {
  margin-right: 0rem !important;
}

.decTop {
  margin-top: -3%;
}

/* @media ( max-width : 991px) {
        .hero {
            height: auto;
            padding: 0px 0 0px 0;
        }
    }
    
    @media screen and (max-width:1350px) {
        section {
            padding: 0px 0;
        }
    } */
.Topbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0px solid red;
  margin-top: -8%;
}

.pdfImage{
  width: 100%;
}

.topcontents {
  width: 50%;
  border: 0px solid red;
}

.topimg {
  width: 50%;
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media screen and (max-width: 991px) {
  .Topbox {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .topcontents {
    width: 100%;
    border: 0px solid red;
    margin-bottom: 20px;
    margin-top: 11%;
  }
  .topcontents h1,
  h2 {
    text-align: center;
  }
  .topimg {
    width: 100%;
    border: 0px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .content {
    padding-top: 1% !important;
  }
  .pdfImage {
    margin-top: 1% !important;
  }
  .wow {  
    margin-bottom: 5% !important;
  }
  .wow h4 {
    font-size: 30px;
  }
  .container-xxl {
    padding-bottom: 2% !important;
    padding-top: 2% !important;
  }
}

@media (max-width: 768px) {
  .Topbox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 0px solid red;
    margin-top: -23%;
  }
  .itrImage {
    margin-top: 0%;
  }
  .keyTop {
    margin-top: 0%;
  }
}

.modal {
  background-color: #0000008c !important;
  opacity: 1 !important;
}

.display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
}

.pdfImage {
  width: 100%;
  margin-top: 5%;
}

.gstFont {
  color: #132f6b;
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
}

.pdfPadding {
  padding: 50px 0px;
}

@media screen and (max-width: 768px) {
  .gstFont {
    color: #132f6b;
    font-size: calc(1.1rem + 2.1vw) !important;
    font-weight: 600;
    line-height: 1.2;
    padding-bottom: 0% !important;
    margin-bottom: 0% !important;
  }
  .fadeCss h4 {
    padding-top: 2%;
  }
}
@media (max-width: 480) {
  .content {
    padding-top: 2% !important;
  }
  .content p {
    padding-top: 100px;
  }
  .pdfImage {
    margin-top: 4% !important;
  }
  .wow {
    margin-bottom: 2% !important;
  }
  .gstImageCss {
    margin-top: 2% !important;
    width: 100%;
  }
  /* .wow h4 {
      font-size: 30px;
    } */
  .container-xxl {
    padding-bottom: 3% !important;
    padding-top: 3% !important;
  }
  .row {
    padding-top: 3% !important;
  }
}
@media only screen and (max-width: 1024px) {
  #maincontainer {
    padding-bottom: 0% !important;
  }
  .gstFont {
    padding-bottom: 2%;
  }
}


@media only screen and (max-width: 412px) and (max-height: 915px) {
  .topimg img {
    width: 74%;
    margin-top: -1%;
    border-radius: 17px;
    height:70%;
  }

  
}

@media only screen and (max-width: 414px) and (max-height:846px) {
  .topimg img {
    width: 81%;
    margin-top: -1%;
    border-radius: 17px;
    height: 86%;
  }

  
}

.img-fluid{
  border-radius: 0 !important;
}
