/* .hero {
    height: 100%;
    margin-top: -5%;
  }
  .about{
    margin-top: 5%;
  }
  .about img{
    margin-top: 17%;
  }
  .display-5 {
    font-size: calc(0.3rem + 2.1vw);
    font-weight: 600;
    line-height: 1.2;
    display: block ruby;
  }
  .me-4 {
    margin-right: 0rem !important;
  }
  .display-1 h1 {
    font-size: 3.5em;
  }
  .Contact-us a {
    background-color: #5cb85c;
  }
.order-first img{
    border-radius:17px;margin-top: 17%;
}
  .features {
    padding-top: 0px;
  }
  .topcontent p {
    font-size: 19px;
    color: #5cb85c;
  }
  .topcontent h1 {
    color: #132f6b;
  }
  .topcontent span {
    color: #fff;
    cursor: pointer;
  }
  .topcontent i {
    color: white;
  }
  .topcontent h1 {
    color: #132f6b;
  }
  .topimg img {
    width: 70%;
    margin-top: -1%;
  }
  .order-last p {
    text-align: justify;
  }
  .section-header p {
    font-size: 15px;
  }
  .faq-title h2 {
    color:#001f8d;
  }
  .modal-body p i {
    font-size: 50px;
    font-style: tahoma;
    text-align: center;
    width: 100%;
  }
  .modal-body img {
    width: 100%;
    height: 169px;
  }
  label {
    font-size: 14px;
  }
  .spanRed {
    color: red;
  }
  #successMsg {
    z-index: 9999;
    padding-top: 59px;
  }
  .modal-dialog {
    z-index: 9999;
  }
  .modal-content {
    width: auto;
    align-items: center;
    background-color: #f5f5f5;
    color: #0f316d;
    z-index: 9999;
  }
  .modal-header i {
    font-size: 50px;
    font-style: tahoma;
  }
  .msg {
    font-size: 20px;
    margin-left: 25px;
  }
  .modal-header h4 {
    font-size: 18px;
    font-weight: bold;
    width: 100%;
  }
  .digSignim {
    margin-top: 25%;
    border-radius: 17px;
  }
  .modal-body img {
    height: 120px;
  }
  @media (max-width: 991px) {
    .hero {
      height: auto;
      padding: 0px 0 0px 0;
    }
  }
  
  .Topbox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 0px solid red;
  }
  
  .topcontent {
    width: 50%;
    border: 0px solid red;
  }
  
  .topimg {
    width: 50%;
    border: 0px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  
  @media screen and (max-width: 991px) {
    .Topbox {
      flex-wrap: wrap;
    }
    .topcontent {
      width: 100%;
      border: 0px solid red;
    }
    .topcontent h1,
    h2 {
      text-align: center;
    }
    .topimg {
      width: 100%;
      border: 0px solid red;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
  
  @media (min-width: 1365px) {
    .call-to-action {
      background-attachment: fixed;
    }
  }
  .dscImage {
    width: -moz-available;
    border-radius: 3%;
    width: 100%;
    margin-top: -6%;
    background-size: auto, auto;
    background-size: cover;
  }
  
  .dscTop {
    margin-top: 0px;
  }
  @media (max-width: 768px) {
    .DscTop {
      margin-top: 0%;
    }
    .addTop {
      margin-top: 0%;
    }
    .docsTop {
      margin-top: 0%;
    }
    .advTop {
      margin-top: 0%;
    }
  
    .dscTop {
      margin-top: 45px;
    }
  
    .dscImage {
      width: -moz-available;
      border-radius: 3%;
      width: 100%;
      margin-top: 6%;
      background-size: auto, auto;
      background-size: cover;
    }
  }
  
  .modal {
    background-color: #0000008c !important;
    opacity: 1 !important;
  }
   */

.hero {
  height: 100%;
  margin-top: -5%;
}
.about {
  margin-top: 5%;
}
.about img {
  margin-top: 17%;
}
.display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
}
.me-4 {
  margin-right: 0rem !important;
}
.display-1 h1 {
  font-size: 3.5em;
}
.Contact-us a {
  background-color: #5cb85c;
}
/* .tradeImage img {
      padding-right: 40px;
    } */
/* .advTop{
      margin-top: -8%;
      }
      
      .docsTop{
      margin-top: -10%
      
      } */

.order-first img {
  border-radius: 17px;
  margin-top: 17%;
}
.features {
  padding-top: 0px;
}
.topcontent p {
  font-size: 19px;
  color: #5cb85c;
}
.topcontent h1 {
  color: #132f6b;
}
.topcontent span {
  color: #fff;
  cursor: pointer;
}
.topcontent i {
  color: white;
}
.topcontent h1 {
  color: #132f6b;
}
.topimg img {
  width: 70%;
  margin-top: -1%;
}
.order-last p {
  text-align: justify;
}
.section-header p {
  font-size: 15px;
}
.faq-title h2 {
  color: #001f8d;
}
.modal-body p i {
  font-size: 50px;
  font-style: tahoma;
  text-align: center;
  width: 100%;
}
.modal-body img {
  width: 100%;
  height: 169px;
}
label {
  font-size: 14px;
}
.spanRed {
  color: red;
}
#successMsg {
  z-index: 9999;
  padding-top: 59px;
}
.modal-dialog {
  z-index: 9999;
}
.modal-content {
  width: auto;
  align-items: center;
  background-color: #f5f5f5;
  color: #0f316d;
  z-index: 9999;
}
.modal-header i {
  font-size: 50px;
  font-style: tahoma;
}
.msg {
  font-size: 20px;
  margin-left: 25px;
}
.modal-header h4 {
  font-size: 18px;
  font-weight: bold;
  width: 100%;
}
.digSignim {
  margin-top: 25%;
  border-radius: 17px;
}
.modal-body img {
  height: 120px;
}
@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 0px 0 0px 0;
  }
}

.Topbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0px solid red;
}

.topcontent {
  width: 50%;
  border: 0px solid red;
}

.topimg {
  width: 50%;
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media screen and (max-width: 991px) {
  .Topbox {
    flex-wrap: wrap;
  }
  .topcontent {
    width: 100%;
    border: 0px solid red;
  }
  .topcontent h1,
  h2 {
    text-align: center;
  }
  .topimg {
    width: 100%;
    border: 0px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

@media (min-width: 1365px) {
  .call-to-action {
    background-attachment: fixed;
  }
}

/* .call-to-action {
      background: linear-gradient(rgba(14, 29, 52, 0.6), rgba(14, 29, 52, 0.8)),
        url(../img/digitalSign.webp) center center;
      background-attachment: scroll, scroll;
      background-size: auto, auto;
      background-size: cover;
      padding: 120px 0px 120px 0px;
    } */

/* .DscTop{
        margin-top:-10%;
      } */

.dscImage {
  width: -moz-available;
  border-radius: 3%;
  width: 100%;
  margin-top: -6%;
  background-size: auto, auto;
  background-size: cover;
}

.dscTop {
  margin-top: 0px;
}
@media (max-width: 768px) {
  .DscTop {
    margin-top: 0%;
  }
  .addTop {
    margin-top: 0%;
  }
  .docsTop {
    margin-top: 0%;
  }
  .advTop {
    margin-top: 0%;
  }

  .dscTop {
    margin-top: 45px;
  }

  .dscImage {
    width: -moz-available;
    border-radius: 3%;
    width: 100%;
    margin-top: 6%;
    background-size: auto, auto;
    background-size: cover;
  }
}

@media (max-width: 480px) {
  #hero {
    margin-top: -20%;
  }
  .topcontent #resp {
    margin-top: -6%;
  }
  .container {
    margin-top: 0%;
    padding: 0%;
  }
  /* .faq {
        padding-top: 10px;
      }
      .features {
        padding: 4px 0px;
      } */

  .lastsection .container {
    padding: 0%;
    margin: 0%;
  }
}

.modal {
  background-color: #0000008c !important;
  opacity: 1 !important;
}
