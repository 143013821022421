.hsn {
  max-width: 1180px;
  background: #fff;
  margin: 0 auto;
  padding: 50px 71px;
  border-radius: 45px;
}
/* New Css adding */
.mobile {
  width: 100%;
  max-width: 680px;
  margin: 0 auto;
}

/* .form1 {
    position: relative;
    width: 100%;
  } */
.form1 {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
}
.form1 input {
  width: 74%;
  height: 60px;
  font-weight: 300;
}
.form1 button {
  /* border: 1px solid #478f27; */
  box-sizing: border-box;
  border-radius: 37px;
  /* margin-top: -15%; */
  font-weight: 500;
  line-height: normal;
  font-size: 17px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #fff;
  height: 50px;
  width: 23%;
  float: right;
  position: absolute;
  bottom: 0px;
  right: 10px;
}
.mn_menu {
  width: 100%;
  max-width: 680px;
  margin: 0 auto;
}
.mn_menu input {
  width: 75.8%;
  height: 50px;
  font-weight: 500;
}
.mn_menu button {
  /* border: 1px solid #478f27; */
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: 500;
  line-height: normal;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #fff;
  height: 50px;
  width: 22%;
  float: right;
}
.hsn p {
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  line-height: normal;
  font-size: 17px;
  letter-spacing: 0.03em;
  color: #535461;
  display: flex;
}
.extraDiv {
  padding: 20px 0 30px;
  display: block;
}
.hsnCodes {
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  line-height: normal;
  font-size: 17px;
  letter-spacing: 0.03em;
  color: #535461;
}

/* New Css End */

/*for desktop view*/
@media (min-width: 300px) and (max-width: 700px) {
  .mn_menu {
    display: none;
  }
}

/*for mobile view*/
@media only screen and (min-width: 700px) {
  .mobile {
    display: none;
  }
}

@media (max-width:480px){
  .form-control::placeholder{
    font-size: 10px;
  }
}


.tables {
	width: 100%;
	margin-bottom: 0;
	color: #212529;
}

.table-responsive {
	line-height: 2.5;
	border-radius: 10px !important;
	border-width: 3.0rem !important;
	box-shadow: rgb(136, 136, 136) 0px 5px 12px !important;
	border-collapse: separate !important;
	overflow: clip !important;
}

.table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
}

.tables thead th {
	/* vertical-align: bottom; */
	border-bottom: 2px solid #dee2e6;
	border-right-color: #fff;
	border-right: 0;
	color:white;
	font-weight: bold;
	width: 74px;
}

.table thead th {
	border-bottom: 1px solid #eaf0f7;
	font-weight: bold;
}



.table-responsive {
	display: block;
	width: 100%;
	overflow-x: scroll;
	-webkit-overflow-scrolling: touch;
}

.table-sm {
	border-collapse: separate;
	border-spacing: 0;
}

.tables td {
	font-size: 13px;
}

.table-bordered td, .table-bordered th {
	border: 1px solid #dee2e6;
	border-top-width: 1px;
	border-top-style: solid;
	border-top-color: rgb(222, 226, 230);
}

.table-sm td, .table-sm th {
	padding: .3rem;
}

th{
font-size: 16px;
}

/* .fa{
  color: #0e306d;
} */



/* General Styling for Paragraphs and Content */
.hsn p {
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.6;
  font-size: 17px;
  letter-spacing: 0.03em;
  color: #535461;
  display: block; /* Ensure paragraphs are stacked vertically */
  margin-bottom: 15px; /* Add some spacing after each paragraph */
}

/* For hsnCodes class */
.hsnCodes {
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.6;
  font-size: 17px;
  letter-spacing: 0.03em;
  color: #535461;
  margin-bottom: 15px; /* Add margin between each text block */
}

/* Adjust the section header (h2) */
.section-header h2 {
  font-size: 24px; /* Default font size for larger screens */
  color: #0e306d;
  margin-bottom: 10px;
  font-weight: 700;
}

/* Responsive adjustments for screens smaller than 768px (tablets and below) */
@media screen and (max-width: 768px) {
  .hsn p, .hsnCodes {
    font-size: 16px; /* Smaller text for better readability */
    line-height: 1.6;
    margin-bottom: 12px; /* Less spacing between paragraphs for mobile view */
  }

  .hsnCodes {
    margin-bottom: 10px; /* Less margin between sections */
  }

  /* Adjust section headers for mobile */
  .section-header h2 {
    font-size: 20px; /* Slightly smaller section headers */
    margin-bottom: 8px;
  }
}

/* Further Adjustments for very small screens (max-width: 480px) */
@media screen and (max-width: 480px) {
  .hsn p, .hsnCodes {
    font-size: 14px; /* Even smaller text for small mobile screens */
    line-height: 1.5;
  }

  .hsnCodes {
    margin-bottom: 8px; /* Tighten spacing for very small screens */
  }

  .section-header h2 {
    font-size: 21px; /* Even smaller headers */
    margin-bottom: 6px;
  }
  ul {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
  }
  .hsn{
    padding: 50px 20px;
  }
  .mobFont{
    font-size: 12px;
  }
  .tables td {
    font-size: 10px;
  }
  
    .tables thead th {
    
      font-size: 10.3px;
    }
  
}

/* Styling for list items with icons */
.hsny ul {
  padding-left: 20px;
  margin-bottom: 15px; /* Provide spacing after list */
}

.hsny ul li {
  margin-bottom: 10px; /* Provide spacing between list items */
}

.hsny ul li i {
  color: #0e306d; /* Change icon color */
  font-size: 16px; /* Adjust icon size for smaller screens */
}

/* Mobile-Friendly Font Sizes */
@media screen and (max-width: 768px) {
  .hsn p, .hsnCodes {
    font-size: 15px; /* Make text even smaller on tablets */
  }

  /* Reduce icon size further on small screens */
  .hsny ul li i {
    font-size: 14px;
  }
}

.hsnCodes .fa{
  color:  #0e306d;
}