/* .hero {
  height: 100%;
}

.display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
}
.about {
  margin-top: 2%;
}
.me-4 {
  margin-right: 0rem !important;
}

.modal-open[style] {
  padding-right: 0px !important;
}
.actionbtn {
  margin-top: 25%;
}
.esiImg {
  margin-top: 0%;
  border-radius: 17px;
}
.order-last {
  margin-top: -2%;
}
.order-first {
  border-radius: 17px;
}
.order-first img {
  margin-top: 10%;
  border-radius: 17px;
}
.feature-icons {
  margin-top: -2%;
}
.modal-body img {
  width: 100%;
  height: 169px;
  border-radius: 17px;
}
.modal-body i {
  font-size: 50px;
  font-style: tahoma;
  text-align: center;
  width: 100%;
}
.faq-title {
  color: #012970;
}
.successMsg {
  z-index: 9999;
  padding-top: 59px;
}
.modal-content {
  width: auto;
  align-items: center;
  background-color: #f5f5f5;
  color: #0f316d;
  z-index: 9999;
}
.modal-content i {
  font-size: 50px;
  font-style: tahoma;
}
.msg {
  font-size: 20px;
  margin-left: 25px;
}
.form span {
  color: red;
}
.modal-dailog {
  z-index: 9999;
}
.order-last p {
  line-height: 26px;
}
.content p {
  line-height: 26px;
}
li {
  font-size: 17px;
}

@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 0px 0 0px 0;
  }
}

@media (min-width: 1365px) {
  .call-to-action {
    background-attachment: fixed;
  }
}
.Topbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0px solid red;
}

.topcontent {
  width: 50%;
  border: 0px solid red;
}

.topimg {
  width: 50%;
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media screen and (max-width: 991px) {
  .Topbox {
    flex-wrap: wrap;
  }
  .topcontent {
    width: 100%;
    border: 0px solid red;
  }
  .topcontent h1,
  h2 {
    text-align: center;
  }
  .topimg {
    width: 100%;
    border: 0px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.top {
  margin-top: -1%;
}

.esiImg {
  margin-top: 24%;
  width: 100%;
}

.whatIncl {
  margin-top: 0%;
}

.docTop {
  margin-top: 0%;
}

.advantageTop {
  margin-top: 0%;
}

@media (max-width: 768px) {
  .esiImg {
    margin-top: 6%;
    width: 100%;
  }
  .top {
    margin-top: -9%;
  }
  .whatIncl {
    margin-top: -37%;
  }
  .docTop {
    margin-top: -20%;
  }
  .advantageTop {
    margin-top: -40%;
  }
}

.modal {
  background-color: #0000008c !important;
  opacity: 1 !important;
} */

.hero {
  height: 100%;
}

.display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
}
.about {
  margin-top: 2%;
}
.me-4 {
  margin-right: 0rem !important;
}

.modal-open[style] {
  padding-right: 0px !important;
}
.actionbtn {
  margin-top: 25%;
}
.esiImg {
  margin-top: 0%;
  border-radius: 17px;
}
.order-last {
  margin-top: 2%;
}
.order-first {
  border-radius: 17px;
  /* margin-top: 17%; */
}
.order-first img {
  margin-top: 10%;
  border-radius: 17px;
}
.content {
  padding-top: 0% !important;
}
.feature-icons {
  margin-top: -2%;
}
.modal-body img {
  width: 100%;
  height: 169px;
  border-radius: 17px;
}
.modal-body i {
  font-size: 50px;
  font-style: tahoma;
  text-align: center;
  width: 100%;
}
.faq-title {
  color: #012970;
}
.successMsg {
  z-index: 9999;
  padding-top: 59px;
}
.modal-content {
  width: auto;
  align-items: center;
  background-color: #f5f5f5;
  color: #0f316d;
  z-index: 9999;
}
.modal-content i {
  font-size: 50px;
  font-style: tahoma;
}
.msg {
  font-size: 20px;
  margin-left: 25px;
}
.form span {
  color: red;
}
.modal-dailog {
  z-index: 9999;
}
.order-last p {
  line-height: 26px;
}
.content p {
  line-height: 26px;
}
li {
  font-size: 15px;
}

@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 0px 0 0px 0;
  }
  /* .about {
    margin-top: -10%;
  } */
  .container {
    margin-top: 0%;
    padding-top: 0%;
    padding-bottom: 0%;
  }
}

@media (min-width: 1365px) {
  .call-to-action {
    background-attachment: fixed;
  }
}

/* .call-to-action {
  background: linear-gradient(rgba(14, 29, 52, 0.6), rgba(14, 29, 52, 0.8)),
    url(../assets/esiBack.jpg) center center;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-size: auto, auto;
  padding: 100px 0;
} */

.Topbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0px solid red;
}

.topcontent {
  width: 50%;
  border: 0px solid red;
}

.topimg {
  width: 50%;
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media screen and (max-width: 991px) {
  .Topbox {
    flex-wrap: wrap;
  }
  .topcontent {
    width: 100%;
    border: 0px solid red;
  }
  .topcontent h1,
  h2 {
    text-align: center;
  }
  .topimg {
    width: 100%;
    border: 0px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.top {
  margin-top: -1%;
}

.esiImg {
  margin-top: 24%;
  width: 100%;
}

.whatIncl {
  margin-top: 0%;
}

.docTop {
  margin-top: 0%;
}

.advantageTop {
  margin-top: 0%;
}

@media (max-width: 768px) {
  .esiImg {
    margin-top: 6%;
    width: 100%;
  }
  .top {
    margin-top: -1%;
  }
  .whatIncl {
    margin-top: -37%;
  }
  .docTop {
    margin-top: -20%;
  }
  .advantageTop {
    margin-top: -40%;
  }
  .whyRecon {
    margin-top: 0% !important;
  }
}
@media (max-width: 768px) {
  .about {
    margin-top: 0%;
    padding-top: 0%;
  }
}

@media (max-width: 480px) {
  .whyRecon {
    margin-top: 0% !important;
  }
  .section-header h2 {
    padding-top: 10px;
  }
}

.modal {
  background-color: #0000008c !important;
  opacity: 1 !important;
}
