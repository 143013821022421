/* .hero {
  height: 100%;
}

.display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
}

.me-4 {
  margin-right: 0rem !important;
}
.features-item {
  width: 100%;
  border-radius: 17px;
}
.features img {
  width: 100%;
  border-radius: 17px;
}
.topcontent p {
  font-size: 19px;
  color: #5cb85c;
}
.topcontent h1 {
  color: #132f6b;
}
.topcontent span {
  color: #fff;
  cursor: pointer;
}
.topcontent i {
  color: white;
}
.topimg img {
  width: 100%;
  margin-top: -1%;
  border-radius: 17px;
}

.modal-body img {
  width: 50%;
}

.modal-body p i {
  font-size: 50px;
  font-style: tahoma;
  text-align: center;
  width: 100%;
}
.modal-body img {
  width: 100%;
  height: 169px;
}
label {
  font-size: 18px;
}
.spanRed {
  color: red;
}
#successMsg {
  z-index: 9999;
  padding-top: 59px;
}
.modal-dialog {
  z-index: 9999;
}
.modal-content {
  width: auto;
  align-items: center;
  background-color: #f5f5f5;
  color: #0f316d;
  z-index: 9999;
}
.modal-header i {
  font-size: 50px;
  font-style: tahoma;
}
.msg {
  font-size: 20px;
  margin-left: 25px;
}
.modal-header h4 {
  font-size: 18px;
  font-weight: bold;
  width: 100%;
}
.digSignim {
  margin-top: 25%;
  border-radius: 17px;
}
.modal-body img {
  height: 120px;
}

@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 0px 0 0px 0;
  }
}

.Topbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0px solid red;
}

.topcontent {
  width: 50%;
  border: 0px solid red;
}

.topimg {
  width: 50%;
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: space-around;
} */
/* 
@media screen and (max-width: 991px) {
  .Topbox {
    flex-wrap: wrap;
  }
  .topcontent {
    width: 100%;
    border: 0px solid red;
  }
  .topcontent h1,
  h2 {
    text-align: center;
  }
  .topimg {
    width: 100%;
    border: 0px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

@media (min-width: 1365px) {
  .call-to-action {
    background-attachment: fixed;
  }
}

/* .call-to-action {
	background: linear-gradient(rgba(14, 29, 52, 0.6), rgba(14, 29, 52, 0.8)),
		url("static/assetse/img/PublicLimitedbackground.PNG") center
		center;
	background-attachment: scroll, scroll;
	background-size: auto, auto;
	background-size: cover;
	padding: 120px 0px 120px 0px;
} */

/* .modal {
  background-color: #0000008c !important;
  opacity: 1 !important;
}

.modal {
  overflow-y: auto;
}

.modal-open {
  overflow: auto;
}

.modal-open[style] {
  padding-right: 0px !important;
} */

.hero {
  height: 100%;
}

.display-5 {
  font-size: calc(0.3rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
  display: block ruby;
}

.me-4 {
  margin-right: 0rem !important;
}
.features-item {
  width: 100%;
  border-radius: 17px;
  margin-top: 1% !important;
}
.features img {
  width: 100%;
  border-radius: 17px;
}
.topcontent p {
  font-size: 19px;
  color: #5cb85c;
}
.topcontent h1 {
  color: #132f6b;
}
.topcontent span {
  color: #fff;
  cursor: pointer;
}
.topcontent i {
  color: white;
}
.topimg img {
  width: 100%;
  margin-top: -1%;
  border-radius: 17px;
}

.modal-body img {
  width: 50%;
}

.modal-body p i {
  font-size: 50px;
  font-style: tahoma;
  text-align: center;
  width: 100%;
}
.modal-body img {
  width: 100%;
  height: 169px;
}
label {
  font-size: 18px;
}
.spanRed {
  color: red;
}
#successMsg {
  z-index: 9999;
  padding-top: 59px;
}
.modal-dialog {
  z-index: 9999;
}
.modal-content {
  width: auto;
  align-items: center;
  background-color: #f5f5f5;
  color: #0f316d;
  z-index: 9999;
}
.modal-header i {
  font-size: 50px;
  font-style: tahoma;
}
.msg {
  font-size: 20px;
  margin-left: 25px;
}
.modal-header h4 {
  font-size: 18px;
  font-weight: bold;
  width: 100%;
}
.digSignim {
  margin-top: 25%;
  border-radius: 17px;
}
.modal-body img {
  height: 120px;
}

@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 0px 0 0px 0;
  }
}

.Topbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 0px solid red;
}

.topcontent {
  width: 50%;
  border: 0px solid red;
}

.topimg {
  width: 50%;
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media screen and (max-width: 991px) {
  .Topbox {
    flex-wrap: wrap;
  }
  .topcontent {
    width: 100%;
    border: 0px solid red;
  }
  .topcontent h1,
  h2 {
    text-align: center;
  }
  .topimg {
    width: 100%;
    border: 0px solid red;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

@media (min-width: 1365px) {
  .call-to-action {
    background-attachment: fixed;
  }
}

/* .call-to-action {
	background: linear-gradient(rgba(14, 29, 52, 0.6), rgba(14, 29, 52, 0.8)),
		url("static/assetse/img/PublicLimitedbackground.PNG") center
		center;
	background-attachment: scroll, scroll;
	background-size: auto, auto;
	background-size: cover;
	padding: 120px 0px 120px 0px;
} */

@media screen and (max-width: 768px) {
  .img-fluid {
    margin-top: 5% !important;
  }
}

.modal {
  background-color: #0000008c !important;
  opacity: 1 !important;
}

.modal {
  overflow-y: auto;
}

.modal-open {
  overflow: auto;
}

.modal-open[style] {
  padding-right: 0px !important;
}
