.fadeInUp {
  visibility: visible;
  animation-delay: 0.1s;
  animation-name: fadeInUp;
}
.section-title {
  color: #5cb85c;
  font-size: 26px;
}
.container h1 {
  color: #0e306d;
  /* font-size: 29px; */
}
.fact-item h3 {
  color: #0e306d;
}
.ob {
  color: #132f6b;
}
p {
  color: #535461;
}
.feature-img img {
  height: 85%;
}
