@media (max-width: 768px) {
  .whyRecon {
    margin-top: 0% !important;
  }
}

@media (max-width: 480px) {
  .whyRecon {
    margin-top: 0% !important;
  }
}
