.article-overview-header {
	background-color: #eee;
	width: 110px;
	padding: 5px;
	text-align: center;
	letter-spacing: 1px;
}

.page-header {
  /* background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(../static/assetse/img/importantDates.png) center center no-repeat;
    background-size: auto, auto; */
  background-size: cover;
}
form.example input[type=text] {
	padding: 10px;
	font-size: 17px;
	border: 1px solid grey;
	float: left;
	width: 80%;
	background: #f1f1f1;
}

form.example button {
	float: left;
	width: 20%;
	padding: 10px;
	background: #2196F3;
	color: white;
	font-size: 17px;
	border: 1px solid grey;
	border-left: none;
	cursor: pointer;
}

form.example button:hover {
	background: #0b7dda;
}

form.example::after {
	content: "";
	clear: both;
	display: table;
}

.form-control {
	border-radius: 0;
}


/***
Bootstrap Line Tabs by @keenthemes
A component of Metronic Theme - #1 Selling Bootstrap 3 Admin Theme in Themeforest: http://j.mp/metronictheme
Licensed under MIT
***/

/* Tabs panel */
.tabbable-panel {
	border: 1px solid #eee;
	padding: 10px;
}

/* Default mode */
.tabbable-line>.nav-tabs {
	border: none;
	margin: 0px;
}

.tabbable-line>.nav-tabs>li {
	margin-right: 2px;
}

.tabbable-line>.nav-tabs>li>a {
	border: 0;
	margin-right: 0;
	color: #737373;
}

.tabbable-line>.nav-tabs>li>a>i {
	color: #a6a6a6;
}

.tabbable-line>.nav-tabs>li.open, .tabbable-line>.nav-tabs>li:hover {
	border-bottom: 4px solid #fbcdcf;
}

.tabbable-line>.nav-tabs>li.open>a, .tabbable-line>.nav-tabs>li:hover>a
	{
	border: 0;
	background: none !important;
	color: #333333;
}

.tabbable-line>.nav-tabs>li.open>a>i, .tabbable-line>.nav-tabs>li:hover>a>i
	{
	color: #a6a6a6;
}

.tabbable-line>.nav-tabs>li.open .dropdown-menu, .tabbable-line>.nav-tabs>li:hover .dropdown-menu
	{
	margin-top: 0px;
}

.tabbable-line>.nav-tabs>li.active {
	border-bottom: 4px solid #f3565d;
	position: relative;
}

.tabbable-line>.nav-tabs>li.active>a {
	border: 0;
	color: #333333;
}

.tabbable-line>.nav-tabs>li.active>a>i {
	color: #404040;
}

.tabbable-line>.tab-content {
	margin-top: -3px;
	background-color: #fff;
	border: 0;
	border-top: 1px solid #eee;
	padding: 15px 0;
}

.portlet .tabbable-line>.tab-content {
	padding-bottom: 0;
}

/* Below tabs mode */
.tabbable-line.tabs-below>.nav-tabs>li {
	border-top: 4px solid transparent;
}

.tabbable-line.tabs-below>.nav-tabs>li>a {
	margin-top: 0;
}

.tabbable-line.tabs-below>.nav-tabs>li:hover {
	border-bottom: 0;
	border-top: 4px solid #fbcdcf;
}

.tabbable-line.tabs-below>.nav-tabs>li.active {
	margin-bottom: -2px;
	border-bottom: 0;
	border-top: 4px solid #f3565d;
}

.tabbable-line.tabs-below>.tab-content {
	margin-top: -10px;
	border-top: 0;
	border-bottom: 1px solid #eee;
	padding-bottom: 15px;
}

.btn {
	
	-webkit-border-radius: 0px;
	
}

.btns {
  background-color: dodgerblue;
  color: white;
  padding: 8px 8px;
  border: none;
  cursor: pointer;
  width:30%;
  margin-top:5%;
  }
  